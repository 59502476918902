import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { WidgetComponent, FormService, LogService, NotificationService, ThumbnailService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';

@Component({
  selector: 'aca-upload-widget-component',
  templateUrl: './upload-widget-component.component.html',
  styleUrls: ['./upload-widget-component.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class UploadWidgetComponentComponent extends WidgetComponent implements OnInit {
  hasFile: boolean;
  displayText: string;
  multipleOption: string = '';
  mimeTypeIcon: string;

  @ViewChild('uploadFiles')
  fileInput: ElementRef;

  constructor(
    formService: FormService,
    private thumbnailService: ThumbnailService,
    protected notificationService: NotificationService,
    protected logService: LogService
  ) {
    super(formService);
  }

  ngOnInit() {
    if (this.field && this.field.value && this.field.value.length > 0) {
      this.hasFile = true;
      this.fixIncompatibilityFromPreviousAndNewForm([]);
    }
    this.getMultipleFileParam();
    this.setDestinationFolderPathFromMappedVariable();
  }

  removeFile(file: any) {
    if (this.field) {
      this.removeElementFromList(file);
    }
  }

  onFileChanged(event: any) {
    const files: File[] = [];
    for (const file of Array.from<File>(event.target.files)) {
      if (!this.isUploaded(file)) {
        files.push(file);
      } else {
        this.notificationService.showWarning('FORM.FIELD.FILE_ALREADY_UPLOADED');
      }
    }
    if (files && files.length > 0) {
      // TODO
    }
  }

  private isUploaded(file: File): boolean {
    const current: Node[] = this.field.value || [];
    return current.some((entry) => entry.name === file.name);
  }

  protected fixIncompatibilityFromPreviousAndNewForm(filesSaved: Node[]) {
    const value: Node[] = [...(this.field.value || [])];
    value.push(...(filesSaved || []));

    this.field.value = value;
    this.field.form.values[this.field.id] = value;

    this.hasFile = value.length > 0;
  }

  getIcon(mimeType: string): string {
    return this.thumbnailService.getMimeTypeIcon(mimeType);
  }

  getMultipleFileParam() {
    if (this.field && this.field.params && this.field.params.multiple) {
      this.multipleOption = this.field.params.multiple ? 'multiple' : '';
    }
  }

  get uploadedFiles(): any[] {
    const result = this.field.value || this.field.form.values[this.field.id];
    return result || [];
  }

  private removeElementFromList(file: any) {
    const filteredValues = this.uploadedFiles.filter((value) => value.id !== file.id);
    this.resetFormValues(filteredValues);
  }

  private resetFormValues(values: any[]) {
    if (values && values.length > 0) {
      this.field.value = values;
      this.field.form.values[this.field.id] = values;
      this.hasFile = true;
    } else {
      this.field.value = [];
      this.field.form.values[this.field.id] = [];
      this.hasFile = false;
    }
  }

  fileClicked(file: any): void {
    this.formService.formContentClicked.next(file);
  }

  isAlfrescoAndLocal(): boolean {
    return this.field?.params?.fileSource?.serviceId === 'all-file-sources';
  }

  isPathVariableType(type: string): boolean {
    return this.field?.params?.fileSource?.destinationFolderPath?.type === type;
  }

  setDestinationFolderPathFromMappedVariable() {
    if (this.isAlfrescoAndLocal()) {
      this.prepareUploadWidgetDestinationFolderPathFromStringVariable();
      this.prepareUploadWidgetDestinationFolderPathFromFolderVariable();
    }
  }

  private prepareUploadWidgetDestinationFolderPathFromStringVariable() {
    if (this.isPathVariableType('string')) {
      this.setUploadWidgetDestinationFolderPath(this.getDestinationFolderPathValue());
    }
  }

  private prepareUploadWidgetDestinationFolderPathFromFolderVariable() {
    if (this.isPathVariableType('folder')) {
      const folder = this.getDestinationFolderPathValue();
      this.setUploadWidgetDestinationFolderPath(folder?.length ? folder[0].id : undefined);
    }
  }

  private setUploadWidgetDestinationFolderPath(path: string) {
    this.field.params.fileSource.destinationFolderPath['value'] = path ? path : undefined;
  }

  private getDestinationFolderPathValue(): any {
    return this.field.form.getProcessVariableValue(this.field.params.fileSource?.destinationFolderPath?.name);
  }
}
