import { Pagination } from '@alfresco/js-api';
import { RestVariableEntry } from './rest-variable-entry';
import { ProcessInstanceEntry } from '../process/process-instance-entry';

/**
 * REST API model
 */
export class RestVariablePagingList {
  pagination: Pagination;
  entries: RestVariableEntry[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.pagination = input.pagination ? new Pagination(input.pagination) : undefined;
      if (input.entries) {
        this.entries = input.entries.map((item: any) => {
          return new ProcessInstanceEntry(item);
        });
      }
    }
  }
}
