import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormService, WidgetComponent } from '@alfresco/adf-core';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'epm-outcomes',
  templateUrl: './outcomes.component.html',
  styleUrls: ['./outcomes.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class OutcomesComponent extends WidgetComponent implements OnInit, OnDestroy {
  private changedStates: { [key: string]: 'enabled' | 'disabled' } = {};
  private buttonStateSub: Subscription;

  constructor(public formService: FormService, private processDynamicFormData: ProcessDynamicFormData) {
    super(formService);
  }

  ngOnInit(): void {
    this.buttonStateSub = this.processDynamicFormData.changeButtonState.subscribe((data) => {
      if (data.buttonId && data.state) {
        this.changedStates[data.buttonId] = data.state;
      }
    });
  }

  onOutcomeClicked(outcome: any) {
    this.field.value = outcome.id;
    this.processDynamicFormData.outcomeClicked.next(outcome);
  }

  isButtonDisabled(buttonId: string) {
    if (this.field.readOnly) {
      return true;
    }
    return !!this.changedStates[buttonId] && this.changedStates[buttonId] === 'disabled';
  }

  ngOnDestroy() {
    this.buttonStateSub?.unsubscribe();
  }
}
