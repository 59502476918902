<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="EPM.BROWSE.START_WORKFLOW.TITLE"> </adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-content>
    <form [formGroup]="createProcessForm" class="epm-start-process">
      <mat-card>
        <mat-card-content style="padding-left: 1%;padding-right: 1%;">
          <mat-form-field [floatLabel]="'always'" class="epm-start-process-site-list">
            <mat-select
              placeholder="{{ 'EPM.START_PROCESS.FORM.LABEL.SITE' | translate }}"
              (selectionChange)="onSiteChange($event)"
              formControlName="site"
              data-automation-id="epm-start-process-site-dropdown">
              <mat-option
                *ngFor="let site of userSites"
                [value]="site.id"
                role="option"
                [attr.data-automation-id]="'adf-start-process-bpmn-option-' + site.id">
                {{ site.title ? site.title : site.id}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [floatLabel]="'always'" class="epm-start-process-bpmn-list">
            <mat-select
              placeholder="{{ 'EPM.START_PROCESS.FORM.LABEL.BPMN' | translate }}"
              (selectionChange)="onBpmnChange($event)"
              formControlName="bpmn"
              data-automation-id="epm-start-process-bpmn-dropdown">
              <mat-option
                *ngFor="let processDef of processDefs"
                [value]="processDef.name"
                role="option"
                [attr.data-automation-id]="'adf-start-process-bpmn-option-' + processDef.name">
                {{ processDef.title ? processDef.title : processDef.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-content *ngIf="true">
          <adf-form-renderer [formDefinition]="processDynamicFormData.formModel | async">
          </adf-form-renderer>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-button
            (click)="cancelStartProcess()"
            id="cancel_process">
            {{'EPM.START_PROCESS.FORM.ACTION.CANCEL'| translate | uppercase}}
          </button>
          <button
            color="primary"
            mat-raised-button
            type="button"
            (click)="onFormSubmit()"
            [disabled]="isSubmitDisabled()"
            data-automation-id="btn-start"
            id="button-start"
            class="btn-start">
            {{'EPM.START_PROCESS.FORM.ACTION.START' | translate | uppercase}}
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
    <div class="center-loading" *ngIf="isLoading">
      <mat-progress-spinner
        class="adf-task-list-loading-margin"
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
