export class Candidate {
  candidateType: string;
  candidateId: string;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
