import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './processes.component.html',
  styleUrls: ['./processes.component.scss']
})
export class ProcessesComponent implements OnInit {
  filtersVisible = true;
  constructor() {}

  ngOnInit(): void {}

  onFilterVisibleChange(visible: boolean) {
    this.filtersVisible = visible;
  }
}
