<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="EPM.BROWSE.TASKS.TITLE"></adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>

  <aca-page-layout-content>
    <div class="main-content">
      <epm-task-filter></epm-task-filter>
      <epm-tasks-list class="epm-tasks-list" #tasksList>
      </epm-tasks-list>
      <adf-pagination acaPagination [target]="tasksList">
      </adf-pagination>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
