import { RestFormModel } from './rest-form-model';

/**
 * REST API model
 */
export class RestFormModelEntry {
  entry: RestFormModel;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.entry = input.entry ? new RestFormModel(input.entry) : undefined;
    }
  }
}
