import { ProcessInstanceTasksPagingList } from './process-instance-tasks-paging-list';

/**
 * REST API model
 */
export class ProcessInstanceTasksList {
  list?: ProcessInstanceTasksPagingList;
  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.list = input.list ? new ProcessInstanceTasksPagingList(input.list) : undefined;
    }
  }
}
