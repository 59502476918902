import { ProcessInstance } from '../api/models/process/process-instance';

/**
 * Model useful when working with processes in the UI
 * Variable conversion is needed for the proper columns/rows assignment
 */
export class ProcessInstanceModel extends ProcessInstance {
  variables = {};

  constructor(processInstance: ProcessInstance) {
    super(processInstance);
    if (processInstance) {
      Object.assign(this, processInstance);
      if (processInstance.processVariables) {
        processInstance.processVariables.forEach((variable) => {
          const dictKey = variable.name;
          this.variables[dictKey] = variable.value;
        });
        delete this.processVariables;
      }
    }
  }
}

export const PROCESS_BACKEND_VARS = [
  'epmWf_attachmentActionsReadOnly',
  'participantsList',
  'nextTaskId',
  'bpm_packageActionGroup',
  'taskIndex',
  'bpm_reassignable',
  'attachmentsList',
  'bpm_priority',
  'initiator',
  'bpm_percentComplete',
  'bpm_sendEMailNotifications',
  'reminderTimer',
  'bpm_hiddenTransitions',
  'workflowinstanceid',
  'taskFormKey',
  'nextTaskUserList',
  'nextTaskUsers',
  'nrOfActiveInstances',
  'stdWf_outcome',
  'bpm_packageItemActionGroup',
  'companyhome',
  '_startTaskCompleted',
  'loopCounter',
  'epmWf_department_isListProperty',
  'multiInstanceData',
  'nrOfInstances',
  'initiatorhome',
  'bpm_outcomePropertyName',
  '${initiator.properties.userName}',
  'bpm_status',
  'cancelled',
  'autoCompleteTimer',
  'nrOfCompletedInstances',
  'cm_name',
  'bpm_comment',
  'pooledActorsHistory',
  'bpm_outcome',
  'epmWf_moveToOutcome',
  'epmWf_dataListWfTaskNode',
  'taskDepartment',
  'epmWf_moveToTaskIndex',
  'cancelReason',
  'specialistRef',
  'assoc_packageItems_added',
  'epmWf_comments',
  'finalMail_interruptedFlag'
];
