import { Candidate } from './candidate';

export class CandidateEntry {
  entry: Candidate;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.entry = input.entry ? new Candidate(input.entry) : undefined;
    }
  }
}
