<div class="plan-widget {{field.className}}"
     [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-readonly]="field.readOnly">
  <label class="adf-label" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
  <mat-form-field>
    <mat-select class="plan-ref-select"
                [id]="field.id"
                [(ngModel)]="field.value"
                [disabled]="field.readOnly"
                (ngModelChange)="onFieldChanged(field)"
                (blur)="markAsTouched()">
      <mat-option value="" id="notApplicable">{{ 'EPM.FORM.NOT_APPLICABLE' | translate}}</mat-option>
      <mat-option *ngFor="let plan of fetchedPlans"
                  [value]="plan.nodeRef"
                  [id]="plan.nodeRef">
        {{plan.name}}
      </mat-option>
      <mat-option id="readonlyOption" *ngIf="isReadOnlyType()" [value]="field.value">{{field.value}}</mat-option>
    </mat-select>
  </mat-form-field>
  <error-widget [error]="field.validationSummary"></error-widget>
  <error-widget class="adf-dropdown-required-message" *ngIf="showRequiredMessage()"
                required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
  <epm-plan-map *ngIf="field.value"
                [ngStyle]="{'height.px': 400, 'display': 'block'}"
                [planUrl]="planUrl"
                [markersData]="planMarkersData"
                (mapClicked)="onMapClick($event)">
  </epm-plan-map>
</div>
