import { RestVariablePagingList } from './rest-variable-paging-list';

/**
 * REST API model
 */
export class RestVariableList {
  list?: RestVariablePagingList;
  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.list = input.list ? new RestVariablePagingList(input.list) : undefined;
    }
  }
}
