import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TasksQueryRequestData } from '../../../api/models/task/tasks-query.model';
import { PaginationModel } from '@alfresco/adf-core';
import { WorkflowDefinitionModel } from '../../../api/models/legacy/workflow-definition.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface TaskListState {
  rows: any[];
  pagination: PaginationModel;
  tasksQuery: TasksQueryRequestData;
}

interface TaskFilterState {
  taskFilterFormValue: any;
  processDefs: WorkflowDefinitionModel[];
}

@Injectable({
  providedIn: 'root'
})
export class TasksDataService {
  /** Emitted from the task filter component and subscribed in to tasks list component */
  filters$ = new BehaviorSubject<TasksQueryRequestData>(null);

  listState: TaskListState;
  filterState: TaskFilterState;

  isSmallScreen: boolean = false;

  constructor(breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
      this.isSmallScreen = result.matches;
    });
  }
}
