export enum PROCESS_STATUS {
  ACTIVE = 'active',
  ANY = 'any',
  COMPLETED = 'completed'
}

/**
 * Filters used in the ProcessFilters component
 */
export class ProcessListFilter {
  siteId: string;
  definitionId: string;
  startedAfter: Date;
  startedBefore: Date;
  startedBy: string;
  assignedTo: string;
  status: PROCESS_STATUS;

  constructor(input?: Partial<ProcessListFilter>) {
    if (input) {
      Object.assign(this, input);
    }
  }

  equalTo(filter2: ProcessListFilter): boolean {
    return (
      filter2 &&
      filter2.siteId === this.siteId &&
      filter2.definitionId === this.definitionId &&
      filter2.startedAfter === this.startedAfter &&
      filter2.startedBefore === this.startedBefore &&
      filter2.assignedTo === this.assignedTo &&
      filter2.status === this.status
    );
  }
}
