import { Injectable } from '@angular/core';
import { ContentClient } from '@alfresco/js-api';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { HttpParams } from '@angular/common/http';
import { ProcessInstanceList } from '../models/process/process-instance-list';

import { ProcessInstanceEntry } from '../models/process/process-instance-entry';
import { throwIfNotDefined } from '../utils/assert';
import { ProcessInstanceQuery } from '../models/process/process-instance-query';
import { RestVariableList } from '../models/rest/rest-variable-list';
import { ProcessInstanceTasksList } from '../models/process/process-instance-tasks-list';
import { ProcessDefinitionsList } from '../models/process/process-definitions-list';
import { ProcessStartResponse } from '../models/process/process-start-response';
import { removeWfDefKeyPrefix } from '../utils/workflow';
import { TasksQueryRequestData } from '../models/task/tasks-query.model';
import { TaskInstanceList } from '../models/task/task-instance-list';
import { TaskInstanceEntry } from '../models/task/task-instance-entry';
import { ItemList } from '../models/item/item-list';
import { CandidateList } from '../models/candidate/candidate-list';
import { RestFormModelList } from '../models/rest-form-model/rest-form-model-list';

/**
 * HTTP REST client for managing workflows (process instances, process definitions, tasks)
 */
@Injectable({
  providedIn: 'root'
})
export class WorkflowApi {
  processClient: ContentClient;

  constructor(private apiService: AlfrescoApiService) {
    if (!this.processClient) {
      this.processClient = new ContentClient(
        apiService.getInstance().config,
        `/api/${apiService.getInstance().config.tenant}/public/workflow/versions/1`,
        apiService.getInstance().httpClient
      );
    } else {
      this.processClient.setConfig(apiService.getInstance().config, `/api/${apiService.getInstance().config.tenant}/public/workflow/versions/1`);
    }
    this.processClient.setAuthentications(this.apiService.getInstance().contentClient.authentications);
  }

  private publicApiCall(path: string, httpMethod: string, params?: any[]): Promise<any> {
    return this.processClient.callApi(path, httpMethod, ...params);
  }

  getProcessesSimple(): Promise<ProcessInstanceList> {
    return this.publicApiCall('/processes', 'GET', [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]);
  }

  getProcessDefinitionImage(processDefinitionId: string): Promise<any> {
    throwIfNotDefined(processDefinitionId, 'processDefinitionId');

    return this.publicApiCall(`/process-definitions/${processDefinitionId}/image`, 'GET', [
      {},
      {},
      {},
      {},
      {},
      ['application/x-www-form-urlencoded'],
      ['image/png'],
      undefined,
      undefined,
      'blob'
    ]);
  }

  /**
   * Get all process definitions.
   * WARNING: it retrieves max 100 items and all versions of deployed workflow
   */
  getProcessDefinitions(): Promise<ProcessDefinitionsList> {
    return this.publicApiCall(`/process-definitions`, 'GET', [{}, {}, {}, {}, {}, ['application/json'], ['application/json']]);
  }

  getProcessDefinitionStartFormModel(processDefinitionId: string): Promise<RestFormModelList> {
    throwIfNotDefined(processDefinitionId, 'processDefinitionId');

    return this.publicApiCall(`/process-definitions/${processDefinitionId}/start-form-model`, 'GET', [
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      RestFormModelList
    ]);
  }

  /**
   * Create a process.
   * @param processDefinitionKey process definition key (without the version, the Activiti engine will take the latest)
   * @param variables process start variables
   * @param items list of attachments
   */
  createProcess(processDefinitionKey: string, variables: object = {}, items?: string[]): Promise<ProcessStartResponse> {
    throwIfNotDefined(processDefinitionKey, 'processDefinitionKey');
    throwIfNotDefined(variables, 'variables');

    return this.postApiCall(
      '/processes',
      { processDefinitionKey: removeWfDefKeyPrefix(processDefinitionKey), variables: variables, items: items },
      ProcessStartResponse
    );
  }

  getProcesses(processInstancesQuery: ProcessInstanceQuery): Promise<ProcessInstanceList> {
    throwIfNotDefined(processInstancesQuery, 'processInstancesQuery');

    let queryParams = new HttpParams();
    if (processInstancesQuery.skipCount) queryParams = queryParams.append('skipCount', processInstancesQuery.skipCount);
    if (processInstancesQuery.maxItems) queryParams = queryParams.append('maxItems', processInstancesQuery.maxItems);
    if (processInstancesQuery.properties) queryParams = queryParams.append('properties', processInstancesQuery.properties.join(','));
    if (processInstancesQuery.orderBy) queryParams = queryParams.append('orderBy', processInstancesQuery.orderBy);
    if (processInstancesQuery.where) queryParams = queryParams.append('where', processInstancesQuery.where);

    return this.publicApiCall('/processes?' + queryParams.toString(), 'GET', [
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      ProcessInstanceList
    ]);
  }

  getProcessInstance(processInstanceId: string): Promise<ProcessInstanceEntry> {
    throwIfNotDefined(processInstanceId, 'processInstanceId');
    return this.publicApiCall(`/processes/${processInstanceId}`, 'GET', [
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      ProcessInstanceEntry
    ]);
  }

  getProcessInstanceVariables(processInstanceId: string): Promise<RestVariableList> {
    throwIfNotDefined(processInstanceId, 'processInstanceId');
    return this.publicApiCall(`/processes/${processInstanceId}/variables`, 'GET', [
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      RestVariableList
    ]);
  }

  getProcessTasks(processInstanceId: string, status: TaskStatus, orderBy?: TasksOrderBy): Promise<ProcessInstanceTasksList> {
    throwIfNotDefined(processInstanceId, 'processInstanceId');
    throwIfNotDefined(status, 'status');

    let queryParams = new HttpParams().set('status', status);
    if (orderBy) {
      queryParams = queryParams.set('orderBy', `${orderBy[0]} ${orderBy[1]}`);
    }

    return this.publicApiCall(`/processes/${processInstanceId}/tasks?` + queryParams.toString(), 'GET', [
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      ProcessInstanceTasksList
    ]);
  }

  getTasks(tasksQuery?: TasksQueryRequestData): Promise<TaskInstanceList> {
    let queryParams = new HttpParams();
    const where = tasksQuery?.where;
    if (where) {
      queryParams = queryParams.set('where', where);
    }
    const orderBy = tasksQuery?.orderBy;
    if (orderBy) {
      queryParams = queryParams.set('orderBy', orderBy);
    }
    const maxItems = tasksQuery?.maxItems;
    if (maxItems) {
      queryParams = queryParams.set('maxItems', maxItems);
    }
    const skipCount = tasksQuery?.skipCount;
    if (skipCount) {
      queryParams = queryParams.set('skipCount', skipCount);
    }

    return this.processClient.callApi(
      '/tasks?' + queryParams.toString(),
      'GET',
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      TaskInstanceList
    );
  }

  getTask(taskId: string): Promise<TaskInstanceEntry> {
    throwIfNotDefined(taskId, 'taskId');
    return this.processClient.callApi(`/tasks/${taskId}`, 'GET', {}, {}, {}, {}, {}, ['application/json'], ['application/json'], TaskInstanceEntry);
  }

  updateTask(taskId: string, action: UpdateTaskAction, payload?: any): Promise<TaskInstanceEntry> {
    throwIfNotDefined(taskId, 'taskId');
    throwIfNotDefined(action, 'action');

    // probably for the optimization
    const selectArray = ['state'];

    const body = { state: action };
    switch (action) {
      case UpdateTaskAction.COMPLETE:
      case UpdateTaskAction.RESOLVE:
        if (payload) {
          selectArray.push('variables');
          body['variables'] = payload;
        }
        break;
      case UpdateTaskAction.DELEGATE:
        if (!payload) {
          throwIfNotDefined(payload, 'assignee');
        }
        selectArray.push('assignee');
        body['assignee'] = payload;
        break;
    }

    const queryParams = new HttpParams().set('select', selectArray.join(','));

    return this.processClient.callApi(
      `/tasks/${taskId}?${queryParams.toString()}`,
      'PUT',
      {},
      {},
      {},
      {},
      body,
      ['application/json'],
      ['application/json'],
      TaskInstanceEntry
    );
  }

  getTaskFormModel(taskId: string): Promise<RestFormModelList> {
    throwIfNotDefined(taskId, 'taskId');
    return this.processClient.callApi(
      `/tasks/${taskId}/task-form-model`,
      'GET',
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      RestFormModelList
    );
  }

  getTaskVariables(taskId: string): Promise<RestVariableList> {
    throwIfNotDefined(taskId, 'taskId');
    return this.processClient.callApi(
      `/tasks/${taskId}/variables`,
      'GET',
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      RestVariableList
    );
  }

  getTaskAttachments(taskId: string): Promise<ItemList> {
    throwIfNotDefined(taskId, 'taskId');
    return this.processClient.callApi(`/tasks/${taskId}/items`, 'GET', {}, {}, {}, {}, {}, ['application/json'], ['application/json'], ItemList);
  }

  /**
   * Add attachmens to a task. If a particular attachment is already added, it will have no effect on it.
   * @param taskId
   * @param attachments
   */
  addTaskAttachments(taskId: string, attachments: { id: string }[]): Promise<ItemList> {
    throwIfNotDefined(taskId, 'taskId');
    throwIfNotDefined(attachments, 'attachments');
    return this.processClient.callApi(
      `/tasks/${taskId}/items`,
      'POST',
      {},
      {},
      {},
      {},
      attachments,
      ['application/json'],
      ['application/json'],
      ItemList
    );
  }

  removeTaskAttachment(taskId: string, attachmentId: string): Promise<any> {
    throwIfNotDefined(taskId, 'taskId');
    throwIfNotDefined(attachmentId, 'attachmentId');
    return this.processClient.callApi(
      `/tasks/${taskId}/items/${attachmentId}`,
      'DELETE',
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json']
    );
  }

  getTaskCandidates(taskId: string): Promise<CandidateList> {
    throwIfNotDefined(taskId, 'taskId');
    return this.processClient.callApi(
      `/tasks/${taskId}/candidates`,
      'GET',
      {},
      {},
      {},
      {},
      {},
      ['application/json'],
      ['application/json'],
      CandidateList
    );
  }

  private postApiCall(path: string, body: object = {}, responseType?: any): Promise<any> {
    const params = [{}, {}, {}, {}, body, ['application/json'], ['application/json'], responseType];
    return this.processClient.callApi(path, 'POST', ...params);
  }
}

export enum TaskStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed'
}

export enum UpdateTaskAction {
  /** Complete a task; payload: variables: [{}] */
  COMPLETE = 'completed',

  /** Assign a pooled task to myself (assignes the assignee to a task) */
  CLAIM = 'claimed',
  /** Return a pooled task to a poll (removed an assignee from a task) */
  UNCLAIM = 'unclaim',

  /** Redirect a task to another person, and when this person completes the task, it will be your again; payload: assignee: string  */
  DELEGATE = 'delegated',

  /** Complete a delegated task; payload: variables: [{}] */
  RESOLVE = 'resolved'
}

export type TasksOrderBy = [TasksOrderByProp, OrderByOrder];
type TasksOrderByProp =
  | 'owner'
  | 'processDefinitionId'
  | 'durationInMs'
  | 'description'
  | 'startedAt'
  | 'priority'
  | 'dueAt'
  | 'processId'
  | 'endedAt'
  | 'name'
  | 'id'
  | 'assignee';
type OrderByOrder = 'ASC' | 'DESC';
