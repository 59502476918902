import { Pagination } from '@alfresco/js-api';
import { RestFormModelEntry } from './rest-form-model-entry';

/**
 * REST API model
 */
export class RestFormModelPagingList {
  pagination: Pagination;
  entries: RestFormModelEntry[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.pagination = input.pagination ? new Pagination(input.pagination) : undefined;
      if (input.entries) {
        this.entries = input.entries.map((item: any) => {
          return new RestFormModelEntry(item);
        });
      }
    }
  }
}
