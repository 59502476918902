import { Pagination } from '@alfresco/js-api';
import { ItemEntry } from './item-entry';

export class ItemPagingList {
  pagination: Pagination;
  entries: ItemEntry[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.pagination = input.pagination ? new Pagination(input.pagination) : undefined;
      if (input.entries) {
        this.entries = input.entries.map((item: any) => {
          return new ItemEntry(item);
        });
      }
    }
  }
}
