import { Component, OnDestroy, OnInit } from '@angular/core';
import { UploadWidgetComponentComponent } from './upload-widget-component.component';
import { ContentInfo, Node, NodeChildAssociation, NodesApi, RelatedContentRepresentation, UserInfo } from '@alfresco/js-api';
import {
  AlfrescoApiService,
  AppConfigService,
  ContentLinkModel,
  DestinationFolderPath,
  FormService,
  FormValues,
  LogService,
  NotificationService,
  ThumbnailService,
  UploadWidgetContentLinkModel
} from '@alfresco/adf-core';
import { ContentNodeSelectorPanelService } from '@alfresco/adf-content-services';
import { AppStore, DownloadNodesAction, SnackbarErrorAction, ViewNodeAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Item } from '../../../../api/models/item/item';
import { EpmContentSelectorService } from './epm-content-selector-service';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { ContentApiService } from '@alfresco/aca-shared';
import { forkJoin, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

export const ALIAS_ROOT_FOLDER = '-root-';
export const RETRIEVE_METADATA_OPTION = 'retrieveMetadata';
export const ALIAS_USER_FOLDER = '-my-';
export const APP_NAME = '-appname-';
export const VALID_ALIAS = [ALIAS_ROOT_FOLDER, ALIAS_USER_FOLDER, '-shared-'];

export enum AttachmentMode {
  ALL = 'all',
  NONE = 'none',
  ADD = 'add'
}

/**
 * value == NodeChildAssociation[]
 * custom params:
 * - attachmentItems: converts Item[] to NodeChildAssociation[] and sets as a value
 * - packageNodeRef: fetches the attachments items based on the provided package Node Ref and sets as a value
 */
@Component({
  selector: 'aca-attach-file-widget-component',
  templateUrl: './attach-file-widget-component.component.html',
  styleUrls: ['./attach-file-widget-component.component.scss']
})
export class AttachFileWidgetComponentComponent extends UploadWidgetComponentComponent implements OnInit, OnDestroy {
  typeId = 'AttachFileWidgetComponentComponent';
  rootNodeId = ALIAS_USER_FOLDER;
  selectedNode: Node;

  _nodesApi: NodesApi;
  get nodesApi(): NodesApi {
    this._nodesApi = this._nodesApi ?? new NodesApi(this.apiService.getInstance());
    return this._nodesApi;
  }
  displayedColumns = ['icon', 'fileName', 'action'];

  private attachmentsFromUrlSub: Subscription;

  constructor(
    formService: FormService,
    logger: LogService,
    thumbnails: ThumbnailService,
    notificationService: NotificationService,
    private appConfigService: AppConfigService,
    private apiService: AlfrescoApiService,
    private contentNodeSelectorPanelService: ContentNodeSelectorPanelService,
    private epmCcontentNodeSelectorService: EpmContentSelectorService,
    private store: Store<AppStore>,
    private router: Router,
    private processDynamicFormData: ProcessDynamicFormData,
    private contentApi: ContentApiService
  ) {
    super(formService, thumbnails, notificationService, logger);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.field.params['attachmentItems']) {
      // convert Item to NodeChildAssociation
      const assocs: NodeChildAssociation[] = [];
      const items: Item[] = <Item[]>this.field.params['attachmentItems'];
      items.forEach((item) => assocs.push(this.itemIoNodeChildAssoc(item)));
      this.field.value = assocs;
      this.onFieldChanged(this.field);
      this.hasFile = true;
    } else if (this.field.params['packageNodeRef']) {
      // TODO fetch packageNodeRef
      console.log(this.field.params['packageNodeRef']);
    }

    if (this.hasFile && this.field.value.length === 1) {
      const files = this.field.value || this.field.form.values[this.field.id];
      this.contentModelFormFileHandler(files[0]);
    }
    this.field.params.displayableCMProperties = this.field.params.displayableCMProperties ?? [];
    this.displayedColumns.splice(2, 0, ...this.field.params.displayableCMProperties?.map((property) => property?.name));

    this.attachmentsFromUrlSub = this.processDynamicFormData.attachmentsFromUrl
      .pipe(
        filter((nodes: string[]) => nodes && nodes.length > 0),
        switchMap((nodes: string[]) => forkJoin(nodes.map((node) => this.contentApi.getNodeInfo(node))))
      )
      .subscribe(
        (result: Node[]) => {
          const assocs: NodeChildAssociation[] = [];
          result.forEach((item) => assocs.push(new NodeChildAssociation(item)));
          this.field.value = assocs;
          this.onFieldChanged(this.field);
          this.hasFile = true;
        },
        (_) => {
          this.store.dispatch(new SnackbarErrorAction('Could not retrieve attachments.'));
        }
      );
  }

  isPathStaticType(): boolean {
    return this.field.params?.fileSource?.destinationFolderPath?.type === 'value';
  }

  isUploadButtonVisible(): boolean {
    return (!this.hasFile || this.multipleOption) && !this.field.readOnly && this.processDynamicFormData.renderer.id !== 'issueWorkflow';
  }

  onRemoveAttachFile(file: File | RelatedContentRepresentation | Node) {
    this.removeFile(file);
    if (file['id'] === this.selectedNode?.id) {
      this.selectedNode = null;
      this.contentModelFormFileHandler();
    }
  }

  fetchAppNameFromAppConfig(): string {
    return this.appConfigService.get('alfresco-deployed-apps')[0]?.name;
  }

  replaceAppNameAliasWithValue(path: string): string {
    if (path?.match(APP_NAME)) {
      const appName = this.fetchAppNameFromAppConfig();
      return path.replace(APP_NAME, appName);
    }
    return path;
  }

  async openSelectDialog() {
    const selectedMode = this.field.params.multiple ? 'multiple' : 'single';
    const nodeId = await this.getDestinationFolderNodeId();
    this.rootNodeId = nodeId ? nodeId : ALIAS_USER_FOLDER;
    this.contentNodeSelectorPanelService.customModels = this.field.params.customModels;

    this.epmCcontentNodeSelectorService
      .openFileSelectDialog(this.rootNodeId, selectedMode, this.isAlfrescoAndLocal(), false, true)
      .subscribe((selections: Node[]) => {
        selections.forEach((node) => (node['isExternal'] = true));
        const selectionWithoutDuplication = this.removeExistingSelection(selections);
        this.fixIncompatibilityFromPreviousAndNewForm(selectionWithoutDuplication);
        if (this.field.value.length === 1) {
          this.contentModelFormFileHandler(selections && selections.length > 0 ? selections[0] : null);
        }
      });
  }

  private async getDestinationFolderNodeId(): Promise<string> {
    let rootNodeId: string;

    switch (this.field?.params?.fileSource?.destinationFolderPath?.type) {
      case 'value':
        rootNodeId = await this.getNodeIdFromPath(this.field.params.fileSource.destinationFolderPath);
        break;
      case 'string':
        rootNodeId = await this.getNodeIdFromPath(this.field.params.fileSource.destinationFolderPath);
        break;
      case 'folder':
        rootNodeId = await this.getNodeIdFromFolderVariableValue(this.field.params.fileSource.destinationFolderPath);
        break;
      default:
        rootNodeId = await this.getNodeIdFromPath({ type: '', value: ALIAS_USER_FOLDER });
        break;
    }

    return rootNodeId;
  }

  async getNodeIdFromPath(destinationFolderPath: DestinationFolderPath): Promise<string> {
    let nodeId: string;
    const destinationPath = this.getAliasAndRelativePathFromDestinationFolderPath(destinationFolderPath.value);
    destinationPath.path = this.replaceAppNameAliasWithValue(destinationPath.path);
    try {
      nodeId = destinationFolderPath.value;
    } catch (error) {
      this.logService.error(error);
    }

    return nodeId;
  }

  async getNodeIdFromFolderVariableValue(destinationFolderPath: DestinationFolderPath): Promise<string> {
    let nodeId: string;
    try {
      nodeId = destinationFolderPath.value;
    } catch (error) {
      this.logService.error(error);
    }

    return nodeId;
  }

  getAliasAndRelativePathFromDestinationFolderPath(_destinationFolderPath: string) {
    return { alias: ALIAS_USER_FOLDER, path: undefined };
  }

  removeExistingSelection(selections: Node[]) {
    const existingNode: Node[] = [...(this.field.value || [])];
    return selections.filter((opt) => !existingNode.some((node) => node.id === opt.id));
  }

  downloadContent(file: Node): void {
    const node: Node = new Node({ name: file.name, id: file.id });
    const nodeEntry = { entry: node };
    this.store.dispatch(new DownloadNodesAction([nodeEntry], {}));
  }

  onAttachFileClicked(nodeSelector: any) {
    nodeSelector.nodeId = nodeSelector.id;
    this.fileClicked(new ContentLinkModel(nodeSelector));
  }

  getWidgetIcon(): string {
    return this.isAlfrescoAndLocal() ? 'file_upload' : 'attach_file';
  }

  onRowClicked(file?: Node) {
    if (this.selectedNode?.id === file?.id) {
      this.selectedNode = null;
    } else {
      this.selectedNode = file;
    }
    this.contentModelFormFileHandler(this.selectedNode);
  }

  contentModelFormFileHandler(file?: any) {
    if (file?.id && this.isRetrieveMetadataOptionEnabled()) {
      const values: FormValues = {};
      this.nodesApi.getNode(file.id).then((acsNode) => {
        const metadata = acsNode?.entry?.properties;
        if (metadata) {
          const keys = Object.keys(metadata);
          keys.forEach((key) => {
            const sanitizedKey = key.replace(':', '_');
            values[sanitizedKey] = metadata[key];
          });
          this.formService.updateFormValuesRequested.next(values);
        }
      });
    }
    this.fileClicked(new UploadWidgetContentLinkModel(file, this.field.id));
  }

  isRetrieveMetadataOptionEnabled(): boolean {
    return this.field?.params?.menuOptions && this.field.params.menuOptions[RETRIEVE_METADATA_OPTION];
  }

  isValidAlias(alias: string): boolean {
    return alias && VALID_ALIAS.includes(alias);
  }

  isSelected(): boolean {
    return this.hasFile;
  }

  ngOnDestroy() {
    this.contentNodeSelectorPanelService.customModels = [];
    this.attachmentsFromUrlSub?.unsubscribe();
  }

  onFileClicked(link: ContentLinkModel) {
    this.store.dispatch(new ViewNodeAction(link.id.toString(), { location: this.router.url }));
  }

  private itemIoNodeChildAssoc(item: Item): NodeChildAssociation {
    return new NodeChildAssociation({
      id: item.id,
      name: item.name,
      isFolder: false,
      isFile: true,
      modifiedAt: item.modifiedAt,
      modifiedByUser: new UserInfo({ id: item.modifiedBy }),
      createdAt: item.createdAt,
      content: new ContentInfo({ mimeType: item.mimeType, sizeInBytes: item.size })
    });
  }
}
