import { ContentNodeSelectorComponent, ContentNodeSelectorComponentData } from '@alfresco/adf-content-services';
import { TranslationService, ContentService, NotificationService, UploadService } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'epm-content-node-selector',
  templateUrl: './epm-content-node-selector.component.html',
  styleUrls: ['./epm-content-node-selector.component.scss']
})
export class EpmContentNodeSelectorComponent extends ContentNodeSelectorComponent {
  constructor(
    translation: TranslationService,
    contentService: ContentService,
    notificationService: NotificationService,
    uploadService: UploadService,
    dialog: MatDialogRef<ContentNodeSelectorComponent>,
    overlayContainer: OverlayContainer,
    @Inject(MAT_DIALOG_DATA) public data: ContentNodeSelectorComponentData
  ) {
    super(translation, contentService, notificationService, uploadService, dialog, overlayContainer, data);
  }

  onSelect(nodeList: Node[]): void {
    super.onSelect(nodeList);
  }

  onClick(): void {
    super.onClick();
  }
}
