import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldModel, FormFieldOption, FormService, LogService, WidgetComponent } from '@alfresco/adf-core';
import * as Leaflet from 'leaflet';
import { ContentApiService } from '@alfresco/aca-shared';
import { extractNodeId } from '../../../../api/utils/node';
import { EpmIssueModel } from '../../../../api/models/legacy/epm-issue-issue.model';
import { LegacyContentService } from '../../../../services/legacy-content.service';
import { take } from 'rxjs/operators';
import { LeafletIcons } from '../../../../model/leaflet-color-markers';
import { EpmIssuePlanModel } from '../../../../api/models/legacy/epm-issue-plan.model';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { IssueRequestFilterModel } from '../../../../api/models/legacy/issue-request-filter.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'epm-plan-widget',
  templateUrl: './plan-widget.component.html',
  styleUrls: ['./plan-widget.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class PlanWidgetComponent extends WidgetComponent implements OnInit, OnDestroy {
  planUrl: string;
  planMarkersData: { issue: EpmIssueModel; icon: Leaflet.Icon }[] = [];

  fetchedPlans: EpmIssuePlanModel[];

  private processCreatedSub: Subscription;

  constructor(
    public formService: FormService,
    private contentApi: ContentApiService,
    private legacyApi: LegacyContentService,
    protected logger: LogService,
    private processDynamicFormData: ProcessDynamicFormData
  ) {
    super(formService);
  }

  ngOnInit(): void {
    const siteid = this._getSiteIdFromForm();
    if (siteid) {
      this.legacyApi
        .getIssuePlans(this._getSiteIdFromForm())
        .pipe(take(1))
        .subscribe((plans) => {
          this.fetchedPlans = plans.map((plan) => {
            return {
              ...plan,
              name: this.removeFileExtension(plan.name)
            };
          });
        });
    } else {
      this.logger.warn('Site id is undefined in the form');
    }
    if (this.field.readOnly && this.field.value) {
      this.updatePlanUrl(this.field.value);
      this.updatePlanMarkersData(this.field.value);
    }

    // when process is created, change yellow icons to blue icons
    this.processCreatedSub = this.processDynamicFormData.newProcessCreated.subscribe((_) => {
      this.planMarkersData = this.planMarkersData.map((d) => {
        if (d.icon === LeafletIcons[1].icon) {
          d.icon = LeafletIcons[0].icon;
        }
        return d;
      });
    });
  }

  onMapClick(event: Leaflet.LatLng) {
    if (this.field.readOnly) {
      return;
    }
    // update form values for marker location
    this.field.form.values['epmIssueWf_planLocationLat'] = event.lat;
    this.field.form.values['epmIssueWf_planLocationLng'] = event.lng;

    // remove new marker from planMarkersData (if exists)
    const newPlanMarkersData = this.planMarkersData.filter((markerData) => markerData.icon === LeafletIcons[0].icon);

    // add new marker to planMarkersData
    newPlanMarkersData.push({
      issue: new EpmIssueModel({ locationLat: event.lat, locationLng: event.lng }),
      icon: LeafletIcons[1].icon
    });

    // update planMarkersData variable
    this.planMarkersData = newPlanMarkersData;
  }

  updatePlanMarkersData(planNodeRef: string) {
    this.legacyApi
      .getIssuePlanIssues(new IssueRequestFilterModel({ planRef: planNodeRef }))
      .pipe(take(1))
      .subscribe((planIssues: EpmIssueModel[]) => {
        if (planIssues && planIssues.length > 0) {
          const markersData: { issue: EpmIssueModel; icon: Leaflet.Icon }[] = [];
          planIssues.forEach((issue) => {
            markersData.push({
              issue: issue,
              icon: LeafletIcons[0].icon
            });
          });
          this.planMarkersData = markersData;
        } else {
          this.planMarkersData = [];
        }
        // if field is read-only it means this is a task edit - add also current issue marker
        if (this.field.readOnly) {
          this.planMarkersData.push(this._getCurrentIssueFormForm());
        }
      });
  }
  removeFileExtension(fileName) {
    if (!fileName || typeof fileName !== 'string') return fileName;
    const lastIndex = fileName.lastIndexOf('.');
    if (lastIndex === -1) return fileName; // No dot found, or it's the first character
    return fileName.substring(0, lastIndex);
  }

  updatePlanUrl(planNodeRef: string) {
    this.planUrl = this.contentApi.getContentUrl(extractNodeId(planNodeRef));
  }

  showRequiredMessage(): boolean {
    return (this.isInvalidFieldRequired() || this.field.value === 'empty') && this.isTouched();
  }

  isReadOnlyType(): boolean {
    return this.field.type === 'readonly';
  }

  getOptionValue(option: FormFieldOption, fieldValue: string): string {
    let optionValue: string = '';
    if (option.id === 'empty' || option.name !== fieldValue) {
      optionValue = option.id;
    } else {
      optionValue = option.name;
    }
    return optionValue;
  }

  onFieldChanged(field: FormFieldModel) {
    super.onFieldChanged(field);

    if (field.value) {
      // clear plan URL and markers (important for slow internet connection)
      this.planUrl = 'dummy';
      this.planMarkersData = [];

      this.updatePlanUrl(field.value);
      this.updatePlanMarkersData(field.value);
      //this.processDynamicFormData.issuePhotoUploadDirId.next(field.value);
    } else {
      this.field.form.values['epmIssueWf_planLocationLat'] = null;
      this.field.form.values['epmIssueWf_planLocationLng'] = null;
    }
  }

  ngOnDestroy() {
    this.processDynamicFormData.issuePhotoUploadDirId.next('');
    this.processCreatedSub?.unsubscribe();
  }

  private _getSiteIdFromForm() {
    return this.field.form.values['epmWf_dataListWfSiteid'];
  }

  private _getCurrentIssueFormForm() {
    return {
      issue: new EpmIssueModel({
        name: '',
        priority: this.field.form.values['bpm_workflowPriority'],
        category: this.field.form.values['epmIssueWf_category'],
        departments: this.field.form.values['epmWf_department'],
        locationLat: this.field.form.values['epmIssueWf_planLocationLat'],
        locationLng: this.field.form.values['epmIssueWf_planLocationLng'],
        expectedCompletionDate: this.field.form.values['epmIssueWf_expectedCompletionDate']
      }),
      icon: LeafletIcons[1].icon
    };
  }
}
