import { Pagination } from '@alfresco/js-api';
import { TaskInstanceEntry } from './task-instance-entry';

/**
 * REST API model
 */
export class TaskInstancePagingList {
  pagination: Pagination;
  entries: TaskInstanceEntry[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.pagination = input.pagination ? new Pagination(input.pagination) : undefined;
      if (input.entries) {
        this.entries = input.entries.map((item: any) => {
          return new TaskInstanceEntry(item);
        });
      }
    }
  }
}
