import { Injectable } from '@angular/core';
import { ProcessListFilter } from '../../../model/process-list-filter';
import { PaginationModel } from '@alfresco/adf-core';
import { BehaviorSubject } from 'rxjs';

/**
 * Preserve Process List state
 */
@Injectable({
  providedIn: 'root'
})
export class ProcessesDataService {
  /** Emitted by the processes-filter and subscribed in the processes-list */
  filters$ = new BehaviorSubject<ProcessListFilter>(null);

  searchedRows: any[];
  filterData: ProcessListFilter;
  searchedPagination: PaginationModel;
  currentInstanceId: string;

  constructor() {}
}
