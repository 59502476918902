import { removeWfDefKeyPrefix } from '../../utils/workflow';

export class TasksQueryRequestData {
  orderProp: TASKS_ORDER_BY_PROP;
  orderByDirection: TASKS_ORDER_BY_DIRECTION;
  status: TASKS_STATUS;
  assignee: string;
  owner: string;
  candidateUser: string;
  candidateGroup: string;
  name: string;
  description: string;
  priority: string;
  processId: string; // process instance ID
  processBusinessKey: string; // process instance business key
  startedBefore: Date;
  startedAfter: Date;
  dueDateBefore: Date;
  dueDateAfter: Date;
  activityDefinitionId: string; // like
  processDefinitionId: string; // i.e. stdWorkflow:11:1504
  processDefinitionKey: string; // i.e. stdWorkflow
  processDefinitionName: string;
  includeTaskVariables: boolean; // include task local variables
  includeProcessVariables: boolean; // include process variables
  maxItems: number; // The maximum number of items to return in the list.
  skipCount: number; // The number of entities that exist in the collection before those included in this list.

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }

  get orderBy() {
    return this.orderProp && this.orderByDirection ? `${this.orderProp} ${this.orderByDirection}` : '';
  }

  get where() {
    const whereArray = [];
    if (this.status) {
      whereArray.push(`status=${this.status}`);
    }
    if (this.assignee) {
      whereArray.push(`assignee=${this.assignee}`);
    }
    if (this.owner) {
      whereArray.push(`owner=${this.owner}`);
    }
    if (this.candidateUser) {
      whereArray.push(`candidateUser=${this.candidateUser}`);
    }
    if (this.candidateGroup) {
      whereArray.push(`candidateGroup=${this.candidateGroup}`);
    }
    if (this.name) {
      whereArray.push(`name=${this.name}`);
    }
    if (this.description) {
      whereArray.push(`description=${this.description}`);
    }
    if (this.priority) {
      whereArray.push(`priority=${this.priority}`);
    }
    if (this.processId) {
      whereArray.push(`processId=${removeWfDefKeyPrefix(this.processId)}`);
    }
    if (this.processBusinessKey) {
      whereArray.push(`processBusinessKey=${removeWfDefKeyPrefix(this.processBusinessKey)}`);
    }
    if (this.startedAfter) {
      whereArray.push(`startedAt>'${this.startedAfter.toISOString()}'`);
    }
    if (this.startedBefore) {
      whereArray.push(`startedAt<'${this.startedBefore.toISOString()}'`);
    }
    if (this.dueDateAfter) {
      whereArray.push(`dueDate>'${this.dueDateAfter.toISOString()}'`);
    }
    if (this.dueDateBefore) {
      whereArray.push(`dueDate<'${this.dueDateBefore.toISOString()}'`);
    }
    if (this.activityDefinitionId) {
      whereArray.push(`activityDefinitionId=${removeWfDefKeyPrefix(this.activityDefinitionId)}`);
    }
    if (this.processDefinitionId) {
      whereArray.push(`processDefinitionId=${removeWfDefKeyPrefix(this.processDefinitionId)}`);
    }
    if (this.processDefinitionKey) {
      whereArray.push(`processDefinitionKey=${removeWfDefKeyPrefix(this.processDefinitionKey)}`);
    }
    if (this.processDefinitionName) {
      whereArray.push(`processDefinitionName=${removeWfDefKeyPrefix(this.processDefinitionName)}`);
    }
    if (this.includeTaskVariables) {
      whereArray.push(`includeTaskVariables=true`);
    }
    if (this.includeProcessVariables) {
      whereArray.push(`includeProcessVariables=true`);
    }
    return whereArray.length > 0 ? `(${whereArray.join(' AND ')})` : '';
  }
}

export enum TASKS_ORDER_BY_PROP {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  PRIORITY = 'priority',
  PROCESS_ID = 'processId',
  ASSIGNEE = 'assignee',
  STARTED_AT = 'startedAt',
  DUE_AT = 'dueAt'
}

export enum TASKS_ORDER_BY_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum TASKS_STATUS {
  ACTIVE = 'active',
  ANY = 'any',
  COMPLETED = 'completed'
}
