import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldModel, FormService, NotificationService, WidgetComponent } from '@alfresco/adf-core';
import { Node } from '@alfresco/js-api';
import { mergeMap } from 'rxjs/operators';
import { EMPTY, from, Observable } from 'rxjs';
import { ProcessService } from '../../../../services/process.service';
import { LegacyContentService } from '../../../../services/legacy-content.service';
import { ContentApiService } from '@alfresco/aca-shared';
import { extractNodeId, wrapNodeId } from '../../../../api/utils/node';

@Component({
  selector: 'epm-photo-upload-widget',
  templateUrl: './photo-upload-widget.component.html',
  styleUrls: ['./photo-upload-widget.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class PhotoUploadWidgetComponent extends WidgetComponent implements OnInit {
  /**
   * value field is the array of NodeReferences - i.e. ["workspace://SpaceStore/324324"]
   */

  hasFile: boolean;
  multipleOption: string = 'true';

  photoUrls: string[] = [];

  savedNodes: Node[] = [];

  /** Uploading indicator */
  isUploading: boolean = false;

  constructor(
    formService: FormService,
    private notificationService: NotificationService,
    private processService: ProcessService,
    private legacyContentService: LegacyContentService,
    private contentApi: ContentApiService
  ) {
    super(formService);
  }

  ngOnInit(): void {
    if (this.field && this.field.value && this.field.value.length > 0) {
      this.hasFile = true;
      this.fixIncompatibilityFromPreviousAndNewForm([]);
    }
  }

  get photoUrlsDynamic(): string[] {
    return this.field.value && this.field.value.length > 0 ? this.photoUrls : [];
  }

  onFileChanged(event: any) {
    const files: File[] = [];
    const filesSaved: Node[] = [];
    for (const file of Array.from<File>(event.target.files)) {
      if (!this.isUploaded(file)) {
        files.push(file);
      } else {
        this.notificationService.showWarning('FORM.FIELD.FILE_ALREADY_UPLOADED');
      }
    }
    if (files && files.length > 0) {
      this.isUploading = true;
      this.getPhotoParentDirectoryId().subscribe((parentDirId) => {
        // now upload all files to the site photos container
        from(files)
          .pipe(mergeMap((file) => this.uploadRawContent(file, parentDirId)))
          .subscribe(
            (res) => {
              filesSaved.push(res);
            },
            (error) => {
              console.log('Error while uploading a file: ' + error);
              this.isUploading = false;
            },
            () => {
              this.fixIncompatibilityFromPreviousAndNewForm(filesSaved);
              this.hasFile = true;
              this.isUploading = false;
            }
          );
      });
    }
  }

  onFieldChanged(field: FormFieldModel) {
    super.onFieldChanged(field);

    if (field.value) {
      // clear photoUrls
    }
  }

  getIssuePhotoUrl(nodeId: string) {
    return this.contentApi.getContentUrl(nodeId);
  }

  allowPreviousFileRemoval(photoUrl: string): boolean {
    if (this.field.readOnly) {
      return false;
    }

    const allowDeleteOption = this.field.options.find((e) => e.id === 'allowDelete');
    if (allowDeleteOption && allowDeleteOption.name === 'true') {
      return true;
    }

    // allow deletion of only now added files
    for (let savedNode of this.savedNodes) {
      if (this.extractNodeIdFromPhotoUrl(photoUrl) === savedNode.id) {
        return true;
      }
    }
    return false;
  }

  private extractNodeIdFromPhotoUrl(photoUrl: string) {
    return photoUrl ? photoUrl.match(/nodes\/(.*)\/content/)[1] : photoUrl;
  }

  removeFile(photoUrl: string) {
    if (this.field) {
      const fileId = this.getIdFromUrl(photoUrl);
      this.removeElementFromList(fileId);
    }
  }

  private getIdFromUrl(photoUrl: string): string {
    const startSubstr = photoUrl.substring(photoUrl.indexOf('/nodes/') + '/nodes/'.length);
    return startSubstr.substring(0, startSubstr.indexOf('/'));
  }

  private isUploaded(file: File): boolean {
    const current: Node[] = this.savedNodes || [];
    return current.some((entry) => entry.name === file.name);
  }

  private uploadRawContent(file: File, parentFolderId: string): Observable<Node> {
    // const opts: any = {
    //   include: ['allowableOperations'],
    //   renditions: 'doclib',
    //   versioningEnabled: true,
    //   overwrite: true,
    //   majorVersion: true,
    //   comment: "",
    //   name: file.name,
    //   autoRename: true,
    //   nodeType: 'epmIssue:photo'
    // }
    // const path: string = "";

    const opts: any = {
      name: Date.now()
    };
    return this.processService.uploadNewFile(file, parentFolderId, opts);
  }

  private getPhotoParentDirectoryId(): Observable<string> {
    const siteid = this._getSiteIdFromForm();
    if (siteid) {
      return this.legacyContentService.getIssuePhotosContainer(siteid);
    }
    return EMPTY;
  }

  private fixIncompatibilityFromPreviousAndNewForm(filesSaved: Node[]) {
    this.savedNodes.push(...filesSaved);

    const value: string[] = [];
    if (Array.isArray(this.field.value)) {
      value.push(...this.field.value);
    } else if (this.field.value) {
      const spitedValueArr = this.field.value.split(',');
      spitedValueArr.forEach((e) => {
        value.push(e);
      });
    }
    filesSaved.forEach((fileSaved) => value.push(wrapNodeId(fileSaved.id)));

    this.field.value = value;
    this.field.form.values[this.field.id] = value;

    this.photoUrls = value.map((id) => this.getIssuePhotoUrl(extractNodeId(id)));

    this.hasFile = value.length > 0;
  }

  private removeElementFromList(fileId: any) {
    const fieldNodeRef = wrapNodeId(fileId);
    const filteredValues = this.field.value.filter((value) => value !== fieldNodeRef);
    this.savedNodes = this.savedNodes.filter((value) => value.id !== fileId);
    if (filteredValues && filteredValues.length > 0) {
      this.field.value = filteredValues;
      this.field.form.values[this.field.id] = filteredValues;
      this.hasFile = true;
    } else {
      this.field.value = [];
      this.field.form.values[this.field.id] = [];
      this.hasFile = false;
    }
    this.photoUrls = this.field.value.map((id) => this.getIssuePhotoUrl(extractNodeId(id)));
  }

  private _getSiteIdFromForm() {
    return this.field.form.values['epmWf_dataListWfSiteid'];
  }
}
