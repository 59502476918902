import { ItemPagingList } from './item-paging-list';

export class ItemList {
  list: ItemPagingList;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.list = input.list ? new ItemPagingList(input.list) : undefined;
    }
  }
}
