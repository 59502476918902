<mat-accordion displayMode="flat">
  <mat-expansion-panel class="mat-elevation-z0" [expanded]="isExpanded" (opened)="isExpanded=true" (expandedChange)="onExpandedChange($event)">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>assessment</mat-icon>
        <div>{{ 'EPM.LAYOUT.FILTERS' | translate }}</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="main-filter-container">
      <form [formGroup]="filtersForm" (ngSubmit)="onFiltersFormSubmit()"
            fxLayout="column" fxLayoutGap="10px" >
        <div class="epm-process-site">
          <mat-form-field fxFlex>
            <mat-label>{{ 'EPM.PROCESS_LIST.FILTERS.SITE_ID' | translate }}</mat-label>
            <mat-select id="filter_site" formControlName="siteId">
              <mat-option [value]="''">{{ 'EPM.FORM.OPTION.SELECT_OPTION' | translate }}</mat-option>
              <mat-option *ngFor="let site of sites" [value]="site.id">{{ site.title }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="epm-process-definition">
          <mat-form-field fxFlex>
            <mat-label>{{ 'EPM.PROCESS_LIST.FILTERS.DEFINITION_ID' | translate }}</mat-label>
            <mat-select id="filter_definition" formControlName="definitionId" style="line-height: initial">
              <mat-option *ngFor="let definitionId of processDefinitionsIds"
                          [value]="definitionId">
                {{ ('EPM.PROCESS.LABEL.' + definitionId.toUpperCase()) | translate }}
              </mat-option>
            </mat-select>
            <mat-error>{{ 'EPM.FORM.ERROR.REQUIRED'| translate }}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex style="margin-top: 1em">
            <mat-label>{{ 'EPM.LABELS.STATUS' | translate }}</mat-label>
            <mat-select
              formControlName="status"
              style="line-height: initial">
              <mat-option [value]="'any'">{{ 'EPM.FORM.TASK_STATUS.ANY' | translate }}</mat-option>
              <mat-option [value]="'completed'">{{ 'EPM.FORM.TASK_STATUS.COMPLETED' | translate }}</mat-option>
              <mat-option [value]="'active'">{{ 'EPM.FORM.TASK_STATUS.ACTIVE' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input
              matInput
              [matDatepicker]="processStartedAfterDatePicker"
              placeholder="{{ 'EPM.PROCESS_LIST.FILTERS.STARTED_AFTER' | translate}}"
              id="processStartedAfter"
              formControlName="startedAfter">
            <mat-hint>{{getDateFormatString()}}</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="processStartedAfterDatePicker">
            </mat-datepicker-toggle>
            <mat-datepicker
              #processStartedAfterDatePicker
              [touchUi]="isSmallScreen">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="input-row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex>
            <input matInput [matDatepicker]="processStartedBeforeDatePicker"
                   placeholder="{{ 'EPM.PROCESS_LIST.FILTERS.STARTED_BEFORE' | translate}}"
                   id="processStartedBefore"
                   formControlName="startedBefore">
            <mat-hint>{{getDateFormatString()}}</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="processStartedBeforeDatePicker">
            </mat-datepicker-toggle>
            <mat-datepicker
              #processStartedBeforeDatePicker
              [touchUi]="isSmallScreen">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="input-row" fxLayout="column" fxLayout.lt-md="column" fxLayoutGap="20px" fxLayoutGap.lt-md="0px">
          <mat-form-field fxFlex (click)="toggleStartedBySelect()">
            <input matInput readonly
                   id="startedBy_id"
                   placeholder="{{ 'EPM.PROCESS_LIST.FILTERS.STARTED_BY' | translate }}"
                   class="form-control"
                   formControlName="startedBy">
            <button *ngIf="filtersForm.get('startedBy').value" matSuffix mat-icon-button aria-label="Clear" (click)="filtersForm.get('startedBy').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <epm-people-search [showPeopleSearch]="isStartedByOpen" class="people-search-field"
                             (userSelect)="onStartedBySelect($event)">
          </epm-people-search>
        </div>
        <div fxFlex>
          <!-- TODO queryAPI and assignee select, based on the people-widget -->
        </div>
        <div class="epm-filter-actions" mat-dialog-actions align="end">
          <button mat-raised-button type="button" (click)="onReset()">{{ 'EPM.FORM.BUTTON.RESET' | translate }}</button>
          <button mat-raised-button color="primary" type="submit"
                  [disabled]="!filtersForm.valid">{{ 'EPM.PROCESS_LIST.FILTERS.SUBMIT' | translate }}</button>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
</mat-accordion>
