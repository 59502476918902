<div class="dl-type-widget {{field.className}}"
     [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-readonly]="field.readOnly">
  <label class="adf-label" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
  <mat-form-field class="epm-dl-type-input" *ngIf="!isProcessTypesLoading">
    <mat-select
      [id]="field.id"
      [required]="isRequired()"
      [(ngModel)]="field.value"
      (ngModelChange)="onFieldChanged(field)"
      [disabled]="field.readOnly || readOnly"
      [matTooltip]="field.tooltip"
      (blur)="markAsTouched()"
      matTooltipPosition="above"
      matTooltipShowDelay="1000">

      <mat-option *ngFor="let processType of filteredProcessTypes" [value]="processType.name">
        {{ processType.name }}
      </mat-option>

    </mat-select>
  </mat-form-field>
  <ng-template *ngIf="isProcessTypesLoading">
    <mat-spinner id="epm-select-process-type-spinner" [diameter]="20"></mat-spinner>
  </ng-template>
  <error-widget [error]="field.validationSummary"></error-widget>
  <error-widget *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
</div>
