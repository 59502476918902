import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAppSelection } from '@alfresco/aca-shared/store';
import { Router } from '@angular/router';
import { SelectionState } from '@alfresco/adf-extensions';
import { Subscription } from 'rxjs';

@Component({
  selector: 'epm-start-process-action',
  template: `
    <ng-container>
      <button mat-menu-item [attr.title]="'EPM.ACTIONS.CREATE_NEW_PROCESS' | translate" (click)="onClick()">
        <adf-icon value="epm:processes"></adf-icon>
        <span>{{ 'EPM.ACTIONS.CREATE_NEW_PROCESS' | translate }}</span>
      </button>
    </ng-container>
  `,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'epm-start-process-action' }
})
export class StartProcessActionComponent implements OnDestroy {
  selection: SelectionState;

  selectionSub: Subscription;

  // get isNodeLocked(): boolean {
  //   return !!(this.selection && !this.selection.isEmpty && isLocked(this.selection.file));
  // }
  constructor(private store: Store<any>, private router: Router) {}

  ngOnInit() {
    this.selectionSub = this.store.select(getAppSelection).subscribe((selection) => (this.selection = selection));
  }

  onClick() {
    if (this.selection && this.selection.nodes && this.selection.nodes.length > 0) {
      const nodeIds = this.selection.nodes.map((node) => node.entry.id);
      this.router.navigate(['epm/process/new'], { queryParams: { nodes: nodeIds } });
    }
  }

  ngOnDestroy() {
    this.selectionSub.unsubscribe();
  }
}
