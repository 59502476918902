import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FormModel, FormValues } from '@alfresco/adf-core';
import { WorkflowFormRenderer } from '../components/form/workflow/workflow-form-renderer';
import { WorkflowDefinitionModel } from '../api/models/legacy/workflow-definition.model';
import { RestFormModel } from '../api/models/rest-form-model/rest-form-model';

/**
 * Class which holds data and events about currently rendered form
 */
@Injectable({ providedIn: 'root' })
export class ProcessDynamicFormData {
  private _formModel: BehaviorSubject<FormModel> = new BehaviorSubject<FormModel>(new FormModel());

  /** Observable of a form model.
   * A processNewComponent can listen to it and form fieds can update formModel
   * */
  public readonly formModel: Observable<FormModel> = this._formModel.asObservable();

  /**
   * Saved form fields definitions fetched from API
   */
  public restFormModel: RestFormModel[];

  outcomeClicked = new Subject<{ id: string; name: string }>();

  get lastFormModelValue() {
    return this._formModel.value;
  }

  /** Observable containing the current workflow definition key **/
  wfDefModel: WorkflowDefinitionModel;

  /** Renderer for the current form rendering */
  renderer: WorkflowFormRenderer;

  /** Attachments which should be automatically added from the URL to start process form
   * It is useful for the "Start Workflow" action
   * */
  attachmentsFromUrl = new BehaviorSubject<string[]>([]);

  /**
   * Called by other widgets to enable/disable an outcome button (from the OutcomesComponent widget)
   * Useful for example in the Additional Specialist button which should be enabled only when specialist is selected
   */
  public changeButtonState: BehaviorSubject<{ buttonId: string; state: 'enabled' | 'disabled' }> = new BehaviorSubject({
    buttonId: '',
    state: 'enabled'
  });

  /** Used in the issue workflow to set/get issue photo parent directory Id */
  issuePhotoUploadDirId = new BehaviorSubject<string>('');

  /** Used in the create new process component to filter workflow types */
  choosenSiteId = new BehaviorSubject<string>('');

  /** Used to inform other components that a new process has been created */
  newProcessCreated = new Subject<boolean>();

  /**
   * Update formModel
   * @param formModel
   */
  updateFormModel(formModel: FormModel) {
    this._formModel.next(formModel);
  }

  /**
   * Update form model fields
   * @param fields
   * @param formValues you can specify existing form field values
   */
  updateFormFieldsModel(fields: any[], formValues?: FormValues): void {
    const currFormModel = this._formModel.value;
    const newFormModel = new FormModel({ ...currFormModel.json, fields: fields }, formValues, currFormModel.readOnly);
    this._formModel.next(newFormModel);
  }

  /**
   * Add new form fields to the Form model
   * @param fields
   */
  addFormFieldsModel(fields: any[]): void {
    const currFormModel = this._formModel.value;
    const newFormModel = new FormModel(
      { ...currFormModel.json, fields: currFormModel.json.fields.concat(fields) },
      currFormModel.values,
      currFormModel.readOnly
    );
    this._formModel.next(newFormModel);
  }

  resetFormModel(): void {
    this._formModel.next(new FormModel());
  }
}
