/**
 * REST API Model
 * from the /epmflow/dynamiclists endpoint
 */
export class DynamiclistsModel {
  data: DynamicListsDataModel[];
  sitesInfo: {};

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}

export interface DynamicListsDataModel {
  name: string;
  ref: string;
  siteid: string;
}
