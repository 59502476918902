import { Observable } from 'rxjs';
import { Item } from '../../../../api/models/item/item';
import { RestFormModel } from '../../../../api/models/rest-form-model/rest-form-model';
import { createFormFieldContainerJson } from '../../utils/form';
import { FormValues, TranslationService } from '@alfresco/adf-core';
import { DlWorkflowService } from './dl-workflow.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DynamicListsDataModel } from '../../../../api/models/epm/dynamiclists.model';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';
import { RetrieveUxDataModel } from '../../../../api/models/epm/retrieve-ux-data.model';
import { TaskInstance } from '../../../../api/models/task/task-instance';
import { AttachmentMode } from '../../widgets/attach-file/attach-file-widget-component.component';
import { AppStore } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { LegacyContentService } from '../../../../services/legacy-content.service';
import { StandardWorkflowFormRenderer } from './standard-workflow-form-renderer';

/**
 * Standard workflow form Renderer.
 */
@Injectable({
  providedIn: 'root'
})
export class StandardWorkflow2FormRenderer extends StandardWorkflowFormRenderer {
  static key = 'stdWorkflow2';
  id = 'stdWorkflow2';
  name = 'Standard workflow - extended';
  description = 'Default standard workflow extended form';

  constructor(
    translation: TranslationService,
    dlWorkflowService: DlWorkflowService,
    processDynamicFormData: ProcessDynamicFormData,
    legacyContentService: LegacyContentService,
    store: Store<AppStore>
  ) {
    super(translation, dlWorkflowService, processDynamicFormData, legacyContentService, store);
  }

  onProcessTypeSelect(processType: DynamicListsDataModel) {
    // add remaining fields
    this.fetchUxData$(processType.ref).subscribe((uxData) => {
      const stdWf2FormFields = [];
      const renderedNameField = this.renderWorkflowNameBasedOnData(uxData);
      if (renderedNameField) {
        stdWf2FormFields.push(renderedNameField);
      }
      stdWf2FormFields.push(
        this.jsonEpmWfDataListWfNode(processType.ref, false, true),
        this.jsonEpmWfDataListWfSiteid(processType.siteid, false, true),
        this.jsonBpmPriority(null, false, false),
        this.jsonEpmWfDataListWfDesc(null, false, false),
        ...this.jsonEpmWfDepartment([], false, uxData),
        ...this.jsonStdWf2MetadataFields(uxData, null, false),
        this.jsonAssocPackageItemsAdded(),
        this.jsonBpmComment(null, false, false)
      );

      const newFormFields = [this.processDynamicFormData.lastFormModelValue.json.fields[0]].concat(stdWf2FormFields);
      const formValues: FormValues = {
        epmWf_dataListWfType: processType.name
      };
      this.processDynamicFormData.updateFormFieldsModel(newFormFields, formValues);
    });
  }

  createTaskFormFields(taskInstance: TaskInstance, variables: object, formFields: RestFormModel[], attachmentItems: Item[]): Observable<object[]> {
    const uxData$ = this.dlWorkflowService.retrieveUxData(variables['epmWf_dataListWfNode'], variables['epmWf_dataListWfTaskNode']);
    const formFieldsObj = {};
    formFields.forEach((field) => (formFieldsObj[field.name] = field));

    switch (taskInstance.formResourceKey) {
      case 'stdWf2:resubmit':
        return uxData$.pipe(
          map((uxData) => {
            return this.renderDefaultTaskFormFields(formFieldsObj, variables, attachmentItems, uxData, false).concat([this.jsonNextTransition()]);
          })
        );
      case 'stdWf2:formalVerification':
        return this.renderStdWfFormalVerification(uxData$, variables, formFieldsObj, attachmentItems);
      case 'stdWf2:additionalOpinion':
        return uxData$.pipe(
          map((uxData) => {
            return [
              ...this.renderDefaultTaskFormFields(formFieldsObj, variables, attachmentItems, uxData, true),
              createFormFieldContainerJson('stdWf_outcome', '', 'outcome-buttons', variables['stdWf_outcome'], false, [
                { id: 'Next', name: 'EPM.FORM.OUTCOME.NEXT' }
              ])
            ];
          })
        );
      default:
        return uxData$.pipe(
          map((uxData) => {
            const formFields = this.renderDefaultTaskFormFields(formFieldsObj, variables, attachmentItems, uxData, true);
            if (uxData.enableAdditionalSpecialist) {
              // add additional specialist field
              formFields.push(this.jsonStdWf2AdditionalSpecialist(null, false));
            }
            return formFields.concat([
              this.jsonStdWfOutcome(
                variables['stdWf_outcome'],
                false,
                false,
                formFieldsObj['stdWf_outcome']?.allowedValues
                  ?.filter((val) => {
                    return !(val === 'AdditionalOpinion' && !uxData.enableAdditionalSpecialist);
                  })
                  .map((opt) => ({
                    id: opt,
                    name: `EPM.FORM.VAR.STDWF.OUTCOME.${opt.toUpperCase()}`
                  }))
              )
            ]);
          })
        );
    }
  }

  private jsonStdWf2MetadataFields(uxData: RetrieveUxDataModel, variables: object, readOnly: boolean): object[] {
    const metadataFields = [];
    uxData.visibleMetadata.forEach((metadata) => {
      let field = undefined;
      switch (metadata) {
        case 'materialName':
          field = createFormFieldContainerJson(
            'stdWf2_materialName',
            this.translation.instant('EPM.FORM.VAR.STDWF2.MATERIALNAME'),
            'text',
            variables ? variables['stdWf2_materialName'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'tradeName':
          field = createFormFieldContainerJson(
            'stdWf2_tradeName',
            this.translation.instant('EPM.FORM.VAR.STDWF2.TRADENAME'),
            'text',
            variables ? variables['stdWf2_tradeName'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'supplier':
          field = createFormFieldContainerJson(
            'stdWf2_supplier',
            this.translation.instant('EPM.FORM.VAR.STDWF2.SUPPLIER'),
            'text',
            variables ? variables['stdWf2_supplier'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'countryOfOrigin':
          const countriesOptions = [];
          const countryOfOriginDef = this.processDynamicFormData.restFormModel.find((fieldModel) => fieldModel.name === 'stdWf2_countryOfOrigin');
          if (countryOfOriginDef) {
            countriesOptions.push(
              ...countryOfOriginDef.allowedValues.map((val) => ({
                id: val,
                name: this.translation.instant(`EPM.FORM.COUNTRIES.${val.toUpperCase()}`)
              }))
            );
          }
          field = createFormFieldContainerJson(
            'stdWf2_countryOfOrigin',
            this.translation.instant('EPM.FORM.VAR.STDWF2.COUNTRYOFORIGIN'),
            'epm-dropdown',
            variables ? variables['stdWf2_countryOfOrigin'] : null,
            true,
            countriesOptions,
            readOnly
          );
          break;
        case 'placeOfBuildIn':
          field = createFormFieldContainerJson(
            'stdWf2_placeOfBuildIn',
            this.translation.instant('EPM.FORM.VAR.STDWF2.PLACEOFBUILDIN'),
            'text',
            variables ? variables['stdWf2_placeOfBuildIn'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'estimatedQuantity':
          field = createFormFieldContainerJson(
            'stdWf2_estimatedQuantity',
            this.translation.instant('EPM.FORM.VAR.STDWF2.ESTIMATEDQUANTITY'),
            'text',
            variables ? variables['stdWf2_estimatedQuantity'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'compliantMaterial':
          field = createFormFieldContainerJson(
            'stdWf2_compliantMaterial',
            this.translation.instant('EPM.FORM.VAR.STDWF2.COMPLIANTMATERIAL'),
            'stdWf2-compliantMaterial',
            variables ? variables['stdWf2_compliantMaterial'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'documentationReference':
          field = createFormFieldContainerJson(
            'stdWf2_documentationReference',
            this.translation.instant('EPM.FORM.VAR.STDWF2.DOCUMENTATIONREFERENCE'),
            'text',
            variables ? variables['stdWf2_documentationReference'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'companyName':
          field = createFormFieldContainerJson(
            'stdWf2_companyName',
            this.translation.instant('EPM.FORM.VAR.STDWF2.COMPANYNAME'),
            'text',
            variables ? variables['stdWf2_companyName'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'companyAddress':
          field = createFormFieldContainerJson(
            'stdWf2_companyAddress',
            this.translation.instant('EPM.FORM.VAR.STDWF2.COMPANYADDRESS'),
            'text',
            variables ? variables['stdWf2_companyAddress'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'scopeOfWorks':
          field = createFormFieldContainerJson(
            'stdWf2_scopeOfWorks',
            this.translation.instant('EPM.FORM.VAR.STDWF2.SCOPEOFWORKS'),
            'text',
            variables ? variables['stdWf2_scopeOfWorks'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'NIP':
          field = createFormFieldContainerJson(
            'stdWf2_NIP',
            this.translation.instant('EPM.FORM.VAR.STDWF2.NIP'),
            'text',
            variables ? variables['stdWf2_NIP'] : null,
            true,
            null,
            readOnly,
            '[0-9]{10}'
          );
          break;
        case 'REGON':
          field = createFormFieldContainerJson(
            'stdWf2_REGON',
            this.translation.instant('EPM.FORM.VAR.STDWF2.REGON'),
            'text',
            variables ? variables['stdWf2_REGON'] : null,
            true,
            null,
            readOnly,
            '^[0-9]{9}$'
          );
          break;
        case 'contractorRepresentative':
          field = createFormFieldContainerJson(
            'stdWf2_contractorRepresentative',
            this.translation.instant('EPM.FORM.VAR.STDWF2.CONTRACTORREPRESENTATIVE'),
            'text',
            variables ? variables['stdWf2_contractorRepresentative'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'contractValue':
          field = createFormFieldContainerJson(
            'stdWf2_contractValue',
            this.translation.instant('EPM.FORM.VAR.STDWF2.CONTRACTVALUE'),
            'text',
            variables ? variables['stdWf2_contractValue'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'changeReason':
          field = createFormFieldContainerJson(
            'stdWf2_changeReason',
            this.translation.instant('EPM.FORM.VAR.STDWF2.CHANGEREASON'),
            'text',
            variables ? variables['stdWf2_changeReason'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'meetingDate':
          field = createFormFieldContainerJson(
            'stdWf2_meetingDate',
            this.translation.instant('EPM.FORM.VAR.STDWF2.MEETINGDATE'),
            'date',
            variables ? variables['stdWf2_meetingDate'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'nonComplianceDate':
          field = createFormFieldContainerJson(
            'stdWf2_nonComplianceDate',
            this.translation.instant('EPM.FORM.VAR.STDWF2.NONCOMPLIANCEDATE'),
            'date',
            variables ? variables['stdWf2_nonComplianceDate'] : null,
            true,
            null,
            readOnly
          );
          break;
        case 'requester':
          const requesterOptions = [];
          const requesterOptionsDef = this.processDynamicFormData.restFormModel?.find((fieldModel) => fieldModel.name === 'stdWf2_requester');
          if (requesterOptionsDef) {
            requesterOptions.push(
              ...requesterOptionsDef.allowedValues.map((val) => ({
                id: val,
                name: this.translation.instant(`EPM.FORM.VAR.STDWF2.REQUESTER_OPTIONS.${val.toUpperCase()}`)
              }))
            );
          }
          field = createFormFieldContainerJson(
            'stdWf2_requester',
            this.translation.instant('EPM.FORM.VAR.STDWF2.REQUESTER'),
            'epm-dropdown',
            variables ? variables['stdWf2_requester'] : null,
            true,
            requesterOptions,
            readOnly
          );
          break;
      }
      if (field) {
        metadataFields.push(field);
      }
    });
    return metadataFields;
  }

  protected renderDefaultTaskFormFields(
    _formFieldsObj: object,
    variables: object,
    attachmentItems: Item[],
    uxData: RetrieveUxDataModel,
    readOnly: boolean
  ): object[] {
    return [
      this.jsonEpmWfComments(variables['epmWf_comments'], false, true),
      this.jsonEpmWfDataListWfNode(variables['epmWf_dataListWfNode'], false, true),
      this.jsonEpmWfDataListWfTaskNode(variables['epmWf_dataListWfTaskNode'], false, true),
      this.jsonEpmWfDataListWfTypeHidden(variables['epmWf_dataListWfType'], false, true),
      this.jsonEpmWfDataListWfSiteid(variables['epmWf_dataListWfSiteid'], false, true),
      this.jsonBpmPriority(variables['bpm_workflowPriority'], false, readOnly),
      this.jsonBpmWorkflowDescription(variables['bpm_workflowDescription'], false, true),
      ...this.jsonStdWf2MetadataFields(uxData, variables, readOnly),
      this.jsonEpmWfDataListWfDesc(variables['epmWf_dataListWfDesc'], false, readOnly),
      ...this.jsonEpmWfDepartment(variables['epmWf_department'], readOnly, uxData),
      this.jsonAssocPackageItemsAdded(
        variables['bpm_package'],
        attachmentItems,
        variables['epmWf_attachmentActionsReadOnly'] === 'NONE' ? AttachmentMode.NONE : AttachmentMode.ADD
      ),
      this.jsonBpmComment(variables['bpm_comment'], false, false)
    ];
  }

  private renderWorkflowNameBasedOnData(uxData: RetrieveUxDataModel) {
    const wfNameRegex = uxData.wfNameRegex;
    const wfNameMetadata = uxData.wfNameMetadata;
    if (wfNameMetadata) {
      // do not render workflow name field
      return null;
    }

    // render workflow name field
    return createFormFieldContainerJson(
      'bpm_workflowDescription',
      this.translation.instant('EPM.FORM.VAR.BPM.WORKFLOWDESCRIPTION'),
      'text',
      null,
      true,
      null,
      false,
      wfNameRegex ? wfNameRegex : null
    );
  }

  protected jsonStdWf2AdditionalSpecialist(value: any, required: boolean) {
    return createFormFieldContainerJson(
      'stdWf2_additionalSpecialist',
      this.translation.instant('EPM.FORM.VAR.STDWF2.ADDITIONALSPECIALIST'),
      'stdWf2-additionalSpecialist',
      value,
      required
    );
  }
}
