<div class="adf-textfield adf-text-widget {{field.className}}"
     [class.adf-invalid]="!field.isValid && isTouched()" [class.adf-readonly]="field.readOnly" [class.adf-left-label-input-container]="field.leftLabels">
  <input
    class="adf-input"
    type="hidden"
    [id]="field.id"
    [required]="isRequired()"
    [value]="field.value"
    [(ngModel)]="field.value"
    (ngModelChange)="onFieldChanged(field)"
    [disabled]="field.readOnly || readOnly"
    [matTooltip]="field.tooltip"
    (blur)="markAsTouched()"
    matTooltipPosition="above"
    matTooltipShowDelay="1000">
</div>
