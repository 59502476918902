<button
  [id]="actionRef.id"
  [color]="color"
  mat-icon-button
  [attr.aria-label]="actionRef.description || actionRef.title | translate"
  [attr.title]="actionRef.description || actionRef.title | translate"
  [matMenuTriggerFor]="menu"
  [disabled]="actionRef.disabled"
  #matTrigger="matMenuTrigger"
>
  <adf-icon [value]="actionRef.icon"></adf-icon>
</button>

<mat-menu #menu="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let child of actionRef.children; trackBy: trackByActionId">
    <ng-container [ngSwitch]="child.type">
      <ng-container *ngSwitchCase="'custom'">
        <adf-dynamic-component [id]="child.component" [data]="child.data"></adf-dynamic-component>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <app-toolbar-menu-item [actionRef]="child" [menuId]="actionRef.id"></app-toolbar-menu-item>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>
