<adf-datatable [rows]="rows"
               [sorting]="sorting"
               [loading]="isLoading"
               [stickyHeader]="true"
               [multiselect]="false"
               [selectionMode]="'single'"
               [contextMenu]="false"
               (row-select)="onRowSelect($any($event))"
               (row-unselect)="onRowUnselect($any($event))"
               (rowClick)="onRowClicked($any($event))"
               (row-keup)="onRowKeyUp($any($event))"
               [rowStyle]="{'minHeight': '30px'}"
                >
  <data-columns>
    <data-column key="name" title="{{ 'EPM.COLUMNS.NAME' | translate }}"></data-column>
    <data-column key="description" title="{{ 'EPM.COLUMNS.DESCRIPTION' | translate }}" class="full-width name-column"></data-column>
    <ng-container *ngIf="!(tasksDataService.isSmallScreen)">
      <data-column key="startedAt" title="{{ 'EPM.COLUMNS.STARTED_AT' | translate }}">
        <ng-template let-entry="$implicit">
          <div>{{entry.row.obj.startedAt | adfLocalizedDate}} </div>
        </ng-template>
      </data-column>
      <data-column key="endedAt" title="{{ 'EPM.COLUMNS.ENDED_AT' | translate }}">
        <ng-template let-entry="$implicit">
          <div>{{entry.row.obj.endedAt | adfLocalizedDate}} </div>
        </ng-template>
      </data-column>
      <data-column key="dueDate" title="{{ 'EPM.COLUMNS.DUE_DATE' | translate }}" type="date">
        <ng-template let-entry="$implicit">
          <div>{{entry.row.obj.dueDate | adfLocalizedDate }} </div>
        </ng-template>
      </data-column>
      <data-column key="priority" title="{{ 'EPM.COLUMNS.PRIORITY' | translate }}">
        <ng-template let-entry="$implicit">
          <div>{{ formatPriority(entry.row.obj.priority) }} </div>
        </ng-template>
      </data-column>
    </ng-container>
  </data-columns>
  <adf-loading-content-template>
    <ng-template>
      <mat-progress-spinner
        class="adf-task-list-loading-margin"
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </ng-template>
    <adf-no-content-template>
      <ng-template>
        <adf-empty-content
                           icon="assignment"
                           [title]="'EPM.TASK_LIST.EMPTY.TITLE' | translate"
                           [subtitle]="'EPM.TASK_LIST.EMPTY.SUBTITLE' | translate">
        </adf-empty-content>
      </ng-template>
    </adf-no-content-template>
  </adf-loading-content-template>
</adf-datatable>
