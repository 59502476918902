import { Pagination } from '@alfresco/js-api';
import { CandidateEntry } from './candidate-entry';

export class CandidatePagingList {
  pagination: Pagination;
  entries: CandidateEntry[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.pagination = input.pagination ? new Pagination(input.pagination) : undefined;
      if (input.entries) {
        this.entries = input.entries.map((entry) => new CandidateEntry(entry));
      }
    }
  }
}
