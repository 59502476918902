import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { QueryApiService } from '../../services/query-api.service';
import { debounceTime } from 'rxjs/operators';
import { Person } from '@alfresco/js-api';
import { DataRowEvent, ShowHeaderMode } from '@alfresco/adf-core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'epm-people-search',
  templateUrl: './people-search.component.html',
  styleUrls: ['./people-search.component.scss']
})
export class PeopleSearchComponent implements OnDestroy {
  @Input() showPeopleSearch = false;

  /** Emitted when a user is selected */
  @Output() userSelect = new EventEmitter<Person>();

  showHeader = ShowHeaderMode.Never;

  searchUserControl: FormControl = new FormControl<string>('');

  searchedUsers: Person[] = [];
  selectedUser: Person;

  private readonly searchInputSub: Subscription;

  constructor(private queryService: QueryApiService) {
    this.searchInputSub = this.searchUserControl.valueChanges.pipe(debounceTime(300)).subscribe((searchWord: string) => {
      if (searchWord && searchWord.trim()) {
        this.performSearch(searchWord);
      }
    });
  }

  resetSearchInput() {
    this.searchUserControl.reset();
  }

  getDisplayUser(firstName: string, lastName: string, delimiter: string = '-'): string {
    firstName = firstName ? firstName : '';
    lastName = lastName ? lastName : '';
    return firstName + delimiter + lastName;
  }

  getInitialUserName(firstName?: string, lastName?: string) {
    firstName = firstName ? firstName[0] : '';
    lastName = lastName ? lastName[0] : '';
    return this.getDisplayUser(firstName, lastName, '');
  }

  onSelectUser(event: DataRowEvent) {
    this.selectedUser = event.value.obj;
    this.resetSearchInput();
    this.userSelect.emit(event.value.obj);
  }

  ngOnDestroy() {
    if (this.searchInputSub) {
      this.searchInputSub.unsubscribe();
    }
  }

  private performSearch(searchWord: string) {
    if (searchWord && searchWord.length > 2) {
      this.queryService.findPeople(searchWord).subscribe((users) => {
        this.searchedUsers = users;
      });
    }
  }
}
