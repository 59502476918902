import { FormFieldOption } from '@alfresco/adf-core';
import { Item } from '../../../api/models/item/item';
import { AttachmentMode } from '../widgets/attach-file/attach-file-widget-component.component';

export function createFormFieldJson(
  id: string,
  name: string,
  type: string,
  value?: any,
  required?: boolean,
  options?: FormFieldOption[],
  readOnly = false,
  regexPattern = null,
  minValue = null,
  maxValue = null
): object {
  return {
    fieldType: 'FormFieldRepresentation',
    id: id,
    name: name,
    type: type,
    value: value,
    required: required,
    readOnly: readOnly,
    overrideId: false,
    colspan: 1,
    placeholder: null,
    minLength: 0,
    maxLength: 0,
    minValue: minValue,
    maxValue: maxValue,
    regexPattern: regexPattern,
    visibilityCondition: null,
    params: {
      existingColspan: 1,
      maxColspan: 2
    },
    options: options
  };
}

export function createFormFieldContainerJson(
  id: string,
  name: string,
  type: string,
  value?: any,
  required?: boolean,
  options?: FormFieldOption[],
  readOnly = false,
  regexPattern = null,
  minValue = null,
  maxValue = null
): {} {
  return {
    fieldType: 'ContainerRepresentation',
    name: 'Container - ' + id,
    type: 'container',
    tab: null,
    numberOfColumns: 1,
    fields: {
      1: [createFormFieldJson(id, name, type, value, required, options, readOnly, regexPattern, minValue, maxValue)]
    }
  };
}

export const containerJson = (formFieldJson: any) => ({
  name: `Container - ${formFieldJson.name}`,
  type: 'container',
  fieldType: 'ContainerRepresentation',
  numberOfColumns: 2,
  required: false,
  readOnly: false,
  sizeX: 2,
  sizeY: 1,
  row: -1,
  col: -1,
  fields: { 1: [formFieldJson] }
});

export function createAttachFileFormField(packageNodeRef?: string, attachmentItems?: Item[], mode?: AttachmentMode) {
  return {
    fieldType: 'ContainerRepresentation',
    name: 'Container - assoc_packageItems_added',
    type: 'container',
    value: null,
    required: false,
    readOnly: false,
    overrideId: false,
    colspan: 1,
    placeholder: null,
    minLength: 0,
    maxLength: 0,
    minValue: null,
    maxValue: null,
    regexPattern: null,
    optionType: null,
    hasEmptyValue: null,
    options: null,
    restUrl: null,
    restResponsePath: null,
    restIdProperty: null,
    restLabelProperty: null,
    tab: null,
    className: null,
    dateDisplayFormat: null,
    layout: null,
    sizeX: 2,
    sizeY: 1,
    row: -1,
    col: -1,
    visibilityCondition: null,
    numberOfColumns: 2,
    fields: {
      1: [
        {
          fieldType: 'AttachFileFieldRepresentation',
          id: 'assoc_packageItems_added',
          name: this.translation.instant('EPM.FORM.VAR.ATTACHMENTS'),
          type: 'upload',
          value: null,
          required: false,
          readOnly: mode === AttachmentMode.NONE,
          overrideId: false,
          colspan: 1,
          params: {
            existingColspan: 1,
            maxColspan: 2,
            fileSource: {
              serviceId: 'all-file-sources',
              name: 'All file sources'
            },
            link: true,
            multiple: 'multiple',
            packageNodeRef: packageNodeRef,
            attachmentItems: attachmentItems,
            menuOptions: {
              show: true,
              download: true,
              retrieveMetadata: false,
              remove: mode !== AttachmentMode.NONE
            }
          },
          visibilityCondition: null
        }
      ]
    }
  };
}
