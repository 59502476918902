/**
 * Extract node ID from node reference
 * Example: workspace://SpaceStore/123 => 123
 * @param nodeRef node reference
 */
export function extractNodeId(nodeRef: string): string {
  if (nodeRef) {
    const nodeRefArr = nodeRef.replace('://', '/').split('/');
    if (nodeRefArr.length === 3) {
      return nodeRefArr[2];
    }
    return nodeRef;
  }
  return '';
}

/**
 * Wrap nodeId to full node reference
 * Example: 123 => workspace://SpaceStore/123
 * @param nodeId
 */
export function wrapNodeId(nodeId: string): string {
  if (nodeId) {
    if (nodeId.includes('://')) {
      // it is already a wrapped node Id
      return nodeId;
    }
    return 'workspace://SpacesStore/' + nodeId;
  }
  return '';
}
