<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="EPM.BROWSE.WORKFLOWS.TITLE"></adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>

  <aca-page-layout-content>
    <div class="main-content">
        <div class="epm-main-content-area">
          <epm-process-filters (filtersVisible)="onFilterVisibleChange($event)">
          </epm-process-filters>
          <processes-list class="epm-processes-list" #processList [ngClass]="{'displayNone': filtersVisible}">
          </processes-list>
          <adf-pagination acaPagination [target]="processList" [ngClass]="{'displayNone': filtersVisible}">
          </adf-pagination>
        </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
