<aca-page-layout>
  <aca-page-layout-header>
    <adf-breadcrumb root="EPM.BROWSE.PLANS.TITLE"></adf-breadcrumb>
  </aca-page-layout-header>

  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>

  <aca-page-layout-content>
    <div class="main-content plan-main-content">
      <div class="map-filters">
        <epm-issues-filter (filters)="onFiltersChange($event)" #filtersRef>
        </epm-issues-filter>
      </div>
      <!-- TODO use epm-plan-map -->
      <div class="map-container" #mapContainer>
        <div class="map"
             leaflet
             [leafletOptions]="leafletOptions"
             [leafletLayers]="leafletLayers"
             (leafletMapReady)="onMapReady($event)"
             (leafletClick)="onMapClick($event)"
        >
        </div>
      </div>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
