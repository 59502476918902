import { LegacyApiService } from '../api/services/legacy-api.service';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { DatalistsDataModel } from '../api/models/legacy/datalists-data.model';
import { catchError, map } from 'rxjs/operators';
import { EpmIssuePlanModel } from '../api/models/legacy/epm-issue-plan.model';
import { EpmIssueModel } from '../api/models/legacy/epm-issue-issue.model';
import { extractNodeId } from '../api/utils/node';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';
import { LogService } from '@alfresco/adf-core';
import { IssueRequestFilterModel } from '../api/models/legacy/issue-request-filter.model';

@Injectable({
  providedIn: 'root'
})
export class LegacyContentService {
  constructor(private store: Store<AppStore>, private logService: LogService, private legacyApiService: LegacyApiService) {}

  getDatalistData(datalistNodeRef: string, fields: string[], filter: { filterId: string; filterData: string }): Observable<DatalistsDataModel> {
    return from(this.legacyApiService.getDatalistData(datalistNodeRef, fields, filter));
  }

  getIssuePlans(siteId: string): Observable<EpmIssuePlanModel[]> {
    return from(this.legacyApiService.getIssuePlans(siteId)).pipe(
      map((obj) => {
        if (!obj.plans) {
          this.handleLegacyError(obj);
        }
        return obj.plans;
      })
    );
  }

  getIssuePlanIssues(data: IssueRequestFilterModel): Observable<EpmIssueModel[]> {
    return from(
      this.legacyApiService.getIssuePlanIssues(
        data.planRef,
        data.completed,
        data.startedAfter,
        data.startedBefore,
        data.startedBy,
        data.priority,
        data.category,
        data.departments,
        data.currAssignedTo
      )
    ).pipe(
      map((obj) => {
        if (!obj.issues) {
          this.handleLegacyError(obj);
        }
        return obj.issues;
      }),
      catchError((err) => {
        this.logService.warn('Error while fetching plan issues');
        this.store.dispatch(new SnackbarErrorAction(err));
        return throwError(() => new Error(err));
      })
    );
  }

  getIssuePhotosContainer(siteid: string): Observable<string> {
    return from(this.legacyApiService.getIssuePhotosContainer(siteid)).pipe(
      map((obj) => {
        if (!obj.container) {
          this.handleLegacyError(obj);
        }
        return extractNodeId(obj.container);
      })
    );
  }

  generateIssueReport(body?: IssueRequestFilterModel): Observable<boolean> {
    return from(this.legacyApiService.generateIssueReport(body)).pipe(
      map((obj) => {
        if (!obj.success) {
          this.handleLegacyError(obj);
        }
        return true;
      })
    );
  }

  private handleLegacyError(resp: any) {
    let errorMsg = 'Unknown error';
    if (resp['error']) {
      errorMsg = resp['error'];
    }
    throwError(() => new Error(errorMsg));
  }
}
