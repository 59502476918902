<adf-upload-drag-area [rootFolderId]="currentFolderId" [disabled]="!canUpload">
  <adf-sidenav-layout
    #layout
    [sidenavMin]="70"
    [sidenavMax]="320"
    [stepOver]="600"
    [hideSidenav]="hideSidenav"
    [expandedSidenav]="expandedSidenav"
    (expanded)="onExpanded($event)"
  >
    <adf-sidenav-layout-header>
      <ng-template let-isMenuMinimized="isMenuMinimized">
        <app-header
          *ngIf="!hideSidenav"
          role="heading"
          aria-level="1"
          (toggleClicked)="layout.toggleMenu()"
          [data]="{ layout }"
          [expandedSidenav]="!isMenuMinimized()">
        </app-header>
      </ng-template>
    </adf-sidenav-layout-header>

    <adf-sidenav-layout-navigation>
      <ng-template let-isMenuMinimized="isMenuMinimized">
        <app-sidenav
          [mode]="isMenuMinimized() ? 'collapsed' : 'expanded'"
          [attr.data-automation-id]="isMenuMinimized() ? 'collapsed' : 'expanded'"
          (swipeleft)="hideMenu($event)"
          
        >
        </app-sidenav>
      </ng-template>
    </adf-sidenav-layout-navigation>

    <adf-sidenav-layout-content>
      <ng-template>
        <router-outlet></router-outlet>
      </ng-template>
    </adf-sidenav-layout-content>
  </adf-sidenav-layout>

  <adf-file-uploading-dialog *ngIf="showFileUploadingDialog" position="left"></adf-file-uploading-dialog>
</adf-upload-drag-area>

<router-outlet name="viewer"></router-outlet>
