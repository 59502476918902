import { CandidatePagingList } from './candidate-paging-list';

export class CandidateList {
  list: CandidatePagingList;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.list = input.list ? new CandidatePagingList(input.list) : undefined;
    }
  }
}
