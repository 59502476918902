import { WorkflowFormRenderer } from './workflow-form-renderer';
import { Observable, of } from 'rxjs';
import { createFormFieldContainerJson } from '../utils/form';
import { RestFormModel } from '../../../api/models/rest-form-model/rest-form-model';
import { Item } from '../../../api/models/item/item';
import { Injectable } from '@angular/core';
import { TranslationService } from '@alfresco/adf-core';
import { TaskInstance } from '../../../api/models/task/task-instance';

/**
 * Default form renderer.
 */
@Injectable({
  providedIn: 'root'
})
export class DefaultWorkflowFormRenderer extends WorkflowFormRenderer {
  constructor(translation: TranslationService) {
    super(translation);
  }

  static readonly key = 'default';
  readonly id = 'default';
  readonly name = 'Default';
  readonly description = 'Default workflow form';

  createStartFormFields(formFields: RestFormModel[]): Observable<object[]> {
    const fields = [];
    formFields.forEach((f) => {
      fields.push(createFormFieldContainerJson(f.name, f.title, f.dataType.replace(/\w+:/, ''), f.defaultValue, f.required));
    });
    return of(fields);
  }

  createTaskFormFields(_taskInstance: TaskInstance, variables: object, formFields: RestFormModel[], attachmentItems: Item[]): Observable<object[]> {
    const fields = [];
    formFields.forEach((f) => {
      fields.push(
        createFormFieldContainerJson(
          f.name,
          f.title,
          f.dataType.replace(/\w+:/, ''),
          variables[f.name] ? variables[f.name] : f.defaultValue,
          f.required
        )
      );
    });
    fields.push(this.jsonAssocPackageItemsAdded(variables['bpm_package'], attachmentItems));
    return of(fields);
  }
}
