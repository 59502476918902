import { Item } from './item';

export class ItemEntry {
  entry: Item;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.entry = input.entry ? new Item(input.entry) : undefined;
    }
  }
}
