import { Person, PersonEntry, PersonPaging } from '@alfresco/js-api';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SearchService } from '@alfresco/adf-content-services';

@Injectable({
  providedIn: 'root'
})
export class QueryApiService {
  constructor(private searchService: SearchService) {}

  findPeople(term: string): Observable<Person[]> {
    return from(this.searchService.queriesApi.findPeople(term)).pipe(
      map((response: PersonPaging) => response.list.entries.map((entry: PersonEntry) => entry.entry))
    );
  }
}
