/**
 * Issue model for the epm-issue functionality
 */
export class EpmIssueModel {
  name: string;
  processId: string;
  creationDate: Date;
  priority: number;
  category: string;
  description: string;
  departments: string[];
  locationLat: number;
  locationLng: number;
  completionDate: Date;
  expectedCompletionDate: Date;
  creatorId: string;
  planRef: string;
  photoRefs: string[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
