/**
 * REST API model
 * Model representation of a single form field
 */
export class RestFormModel {
  dataType: string;
  title: string;
  qualifiedName: string;
  name: string;
  required: boolean;
  defaultValue: string;
  allowedValues: string[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
