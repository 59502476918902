import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AppConfigService,
  DataColumn,
  DataRow,
  DataRowEvent,
  DataTableSchema,
  DEFAULT_PAGINATION,
  PaginatedComponent,
  PaginationModel,
  TranslationService
} from '@alfresco/adf-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProcessService } from '../../../../services/process.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { ProcessInstanceList } from '../../../../api/models/process/process-instance-list';
import { ProcessInstanceModel } from '../../../../model/process-instance-model';
import { ProcessListFilter } from '../../../../model/process-list-filter';
import { createInstanceQuery, ProcessInstanceQuery } from '../../../../api/models/process/process-instance-query';
import { Router } from '@angular/router';
import { ProcessesDataService } from '../processes-data-service';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';

/**
 * In the app.config.json file in the {"epm-process-list": {"presets": ...}}
 * you can set presets for the "layoutPresets" variable, which
 * definies columns. If they are not set, default columns will be shown (processPresetsDefaultModel)
 */
const PRESET_KEY = 'epm-process-list.presets';

const PROCESS_LIST_COLUMNS_DEFAULT_MODEL = {
  default: [
    {
      key: 'variables.bpm_workflowDescription',
      type: 'text',
      title: 'EPM.PROCESS_LIST.PROPERTIES.NAME',
      sortable: false
    },
    {
      key: 'startedAt',
      type: 'date',
      title: 'EPM.PROCESS_LIST.PROPERTIES.CREATED',
      cssClass: 'hidden',
      sortable: true
    }
  ]
};

@Component({
  selector: 'processes-list',
  templateUrl: './processes-list.component.html',
  styleUrls: ['./processes-list.component.scss']
})
export class ProcessesListComponent extends DataTableSchema implements OnInit, OnDestroy, PaginatedComponent {
  /** the current pagination page */
  page: number = 0;

  /** Filters from the process-filters component. */
  filters: ProcessListFilter;

  /** Defines the sort ordering of the list. Possible values are `created-desc`, `created-asc`,
   * `ended-desc`, `ended-asc`.
   */
  sorting: string[] = ['startedAt', 'desc'];

  /** The number of processes to fetch in each page. */
  size: number = DEFAULT_PAGINATION.maxItems;

  /** Toggles multiple row selection, which renders checkboxes at the beginning of each row */
  multiselect: boolean = false;

  /** Row selection mode. Can be none, `single` or `multiple`. For `multiple` mode,
   * you can use Cmd (macOS) or Ctrl (Win) modifier key to toggle selection for
   * multiple rows.
   */
  selectionMode: string = 'single'; // none|single|multiple

  /** Toggles default selection of the first row */
  selectFirstRow: boolean = false;

  /** Toggles the sticky header mode. */
  stickyHeader: boolean = true;

  /** Toggles custom context menu for the component. */
  showContextMenu: boolean = false;

  /**
   * Resolver function is used to show dynamic complex column objects
   * see the docs to learn how to configure a resolverFn.
   */
  resolverFn: (row: DataRow, col: DataColumn) => any = null;

  /** Emitted when a row in the process list is clicked. */
  @Output()
  rowClick = new EventEmitter<string>();

  /** Emitted when the list of process instances has been loaded successfully from the server. */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  success = new EventEmitter<ProcessInstanceList>();

  /** Emitted when an error occurs while loading the list of process instances from the server. */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  error = new EventEmitter<any>();

  processInstanceQuery: ProcessInstanceQuery;
  currentInstanceId: string;

  isLoading: boolean = false;
  rows: any[] = [];
  pagination: BehaviorSubject<PaginationModel>;

  private readonly onDestroy$ = new Subject<boolean>();

  constructor(
    private processService: ProcessService,
    appConfig: AppConfigService,
    private router: Router,
    private processesDataService: ProcessesDataService,
    private translation: TranslationService,
    private store: Store<AppStore>
  ) {
    super(appConfig, PRESET_KEY, PROCESS_LIST_COLUMNS_DEFAULT_MODEL);
    this.size = 500; // TODO: create better API with all filters. Filtering shouldn't be in the browser

    this.pagination = new BehaviorSubject<PaginationModel>({
      maxItems: this.size,
      skipCount: 0,
      totalItems: 0
    });
  }

  ngOnInit(): void {
    // create table columns
    this.createDatatableSchema();

    // try to restore state
    //this.restoreState();

    // subscribe to filters data change
    this.processesDataService.filters$.pipe(takeUntil(this.onDestroy$)).subscribe((filterData) => {
      if (filterData != null) {
        this.filters = filterData;
        this.reload();
      }
    });
  }

  /**
   * Emit the event rowClick passing the current task id when the row is clicked
   *
   * @param event
   */
  onRowClick(event: DataRowEvent) {
    const item = event;
    this.currentInstanceId = item.value.getValue('id');
    this.router.navigate(['epm', 'process', this.currentInstanceId]);
  }

  /**
   * Emit the event rowClick passing the current task id when pressed the Enter key on the selected row
   *
   * @param event
   */
  onRowKeyUp(event: CustomEvent<any>) {
    if (event.detail.keyboardEvent.key === 'Enter') {
      event.preventDefault();

      this.currentInstanceId = event.detail.row.getValue('id');
      this.rowClick.emit(this.currentInstanceId);
    }
  }

  /**
   * For the PaginationComponent target property
   * @param params
   */
  updatePagination(params: PaginationModel) {
    this.processesDataService.searchedPagination = params;
    const needsReload = params.maxItems || params.skipCount;

    this.size = params.maxItems;
    this.page = this.currentPage(params.skipCount, params.maxItems);

    if (needsReload) {
      this.reload();
    }
  }

  onSortingChanged(event) {
    this.sorting = [event.detail.key, event.detail.direction];
    this.reload();
  }

  /**
   * Check if the list is empty
   */
  isListEmpty(): boolean {
    return !this.rows || this.rows.length === 0;
  }

  ngOnDestroy(): void {
    this.processesDataService.searchedRows = this.rows;
    this.processesDataService.currentInstanceId = this.currentInstanceId;

    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private currentPage(skipCount: number, maxItems: number): number {
    return skipCount && maxItems ? Math.floor(skipCount / maxItems) : 0;
  }

  private load(requestNode: ProcessInstanceQuery) {
    this.isLoading = true;

    this.processService
      .getProcessInstances(requestNode)

      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        (response) => {
          const entries = response.list?.entries?.map((entry) => new ProcessInstanceModel(entry.entry));
          if (this.filters.siteId) {
            // filter sites (this is not handled by the API, so it must be handled here)
            this.rows = entries.filter(
              (processInstance: ProcessInstanceModel) => processInstance.variables['epmWf_dataListWfSiteid'] === this.filters.siteId
            );
          } else {
            this.rows = entries;
          }
          this.success.emit(response);

          const paginationModel: PaginationModel = {
            count: response.list?.entries?.length,
            maxItems: this.size,
            skipCount: this.page * this.size,
            totalItems: response.list?.pagination?.totalItems
          };
          this.pagination.next(paginationModel);
          this.processesDataService.searchedPagination = paginationModel;
        },
        (error) => {
          this.error.emit(error);
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('EPM.PROCESS_LIST.ERROR.FETCH')));
          this.clearList();
        }
      );
  }

  private clearList(): void {
    this.rows = [];
    this.pagination.next(new PaginationModel());
  }

  private reload() {
    this.processInstanceQuery = createInstanceQuery(this.page, this.size, this.sorting, this.filters);
    this.load(this.processInstanceQuery);
  }

  // private restoreState() {
  // if (this.processesDataService.searchedRows && this.processesDataService.searchedRows.length > 0) {
  //   this.rows = this.processesDataService.searchedRows;
  // }
  // if (this.processesDataService.searchedPagination) {
  //   this.size = this.processesDataService.searchedPagination.maxItems;
  //   this.page = this.currentPage(this.processesDataService.searchedPagination.skipCount, this.processesDataService.searchedPagination.maxItems);
  //   this.pagination.next({
  //     count: this.processesDataService.searchedPagination.count,
  //     maxItems: this.processesDataService.searchedPagination.maxItems,
  //     skipCount: this.processesDataService.searchedPagination.skipCount,
  //     totalItems: this.processesDataService.searchedPagination.totalItems
  //   });
  // }
  // if (this.processesDataService.currentInstanceId) {
  //   this.currentInstanceId = this.processesDataService.currentInstanceId;
  //   if (!this.isListEmpty()) {
  //     const selectedRow = this.rows.find((r) => r['id'] === this.processesDataService.currentInstanceId);
  //     if (selectedRow) {
  //       // unselect all rows
  //       this.rows.forEach((r) => (r.isSelected = false));
  //       // selected previously clicked row
  //       selectedRow.isSelected = true;
  //     }
  //   }
  // }
  // this.reload();
  // }
}
