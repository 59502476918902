/**
 * Plan model for the epm-issue functionality
 */
export class EpmIssuePlanModel {
  name: string;
  nodeRef: string;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
