<aca-page-layout [hasError]="!isValidId">
  <aca-page-layout-header>
    <div class="epm-breadcrumb">
      <nav role="navigation">
        <div>
          {{ ((editMode) ? 'EPM.BROWSE.TASK_EDIT.TITLE': 'EPM.BROWSE.TASK_DETAILS.TITLE') | translate}} - {{ taskInstance?.name }} {{ (taskVariables["bpm_workflowDescription"]) ? ' (' + taskVariables["bpm_workflowDescription"] + ')' : '' }}
        </div>
      </nav>
    </div>

    <adf-toolbar class="adf-toolbar--inline task-edit-toolbar">
      <button mat-icon-button *ngIf="editTaskVisible()" color="accent"
              (click)="startEditing()"
              type="button"
              class="task-edit-toolbar-button">
        <mat-icon title="{{ 'EPM.TASK_EDIT.TOOLBAR.EDIT_TASK' | translate }}">edit</mat-icon>
      </button>
      <button mat-icon-button
              (click)="onWorkflowDetailsClicked()"
              type="button"
              class="task-edit-toolbar-button">
        <mat-icon title="{{ 'EPM.TASK_EDIT.TOOLBAR.WORKFLOW_DETAILS' | translate }}">details</mat-icon>
      </button>
      <button mat-icon-button aria-label="close task details" (click)="goBack()" class="task-edit-toolbar-button"><mat-icon>close</mat-icon></button>
    </adf-toolbar>
  </aca-page-layout-header>

  <aca-page-layout-error>
    <aca-generic-error></aca-generic-error>
  </aca-page-layout-error>

  <aca-page-layout-content>
    <div class="task-edit-content">
      <div class="task-edit-form-content">
        <adf-form-renderer [formDefinition]="formModel">
        </adf-form-renderer>
        <div *ngIf="editMode && formModel.hasOutcomes()" class="adf-form-mat-card-actions">
          <ng-container *ngFor="let outcome of formModel.outcomes">
            <button *ngIf="outcome.isVisible && outcome.id !== '$save'" [id]="'adf-form-'+ outcome.name  | formatSpace"
                    mat-button
                    (click)="onOutcomeClicked(outcome)">
              {{outcome.name | translate | uppercase }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="center-loading" *ngIf="isLoading">
      <mat-progress-spinner
        class="adf-task-list-loading-margin"
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </aca-page-layout-content>
</aca-page-layout>
