/*!
 * @license
 * Alfresco Example Content Application
 *
 * Copyright (C) 2005 - 2020 Alfresco Software Limited
 *
 * This file is part of the Alfresco Example Content Application.
 * If the software was purchased under a paid Alfresco license, the terms of
 * the paid license agreement will prevail.  Otherwise, the software is
 * provided under the following open source license terms:
 *
 * The Alfresco Example Content Application is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Lesser General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * The Alfresco Example Content Application is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with Alfresco. If not, see <http://www.gnu.org/licenses/>.
 */

import { AppExtensionService, ContentApiService, PageComponent } from '@alfresco/aca-shared';
import { AppStore } from '@alfresco/aca-shared/store';
import { UploadService } from '@alfresco/adf-core';
import { MinimalNodeEntity, MinimalNodeEntryEntity, PathElementEntity, PathInfo } from '@alfresco/js-api';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, map } from 'rxjs/operators';
import { ContentManagementService } from '../../services/content-management.service';
import { DocumentListPresetRef, sortByOrder } from '@alfresco/adf-extensions';

@Component({
  templateUrl: './favorites.component.html'
})
export class FavoritesComponent extends PageComponent implements OnInit {
  isSmallScreen = false;

  columns: DocumentListPresetRef[] = [];

  constructor(
    private router: Router,
    store: Store<AppStore>,
    extensions: AppExtensionService,
    private contentApi: ContentApiService,
    content: ContentManagementService,
    private uploadService: UploadService,
    private breakpointObserver: BreakpointObserver
  ) {
    super(store, extensions, content);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions = this.subscriptions.concat([
      this.uploadService.fileUploadComplete.pipe(debounceTime(300)).subscribe((_) => this.reload()),
      this.uploadService.fileUploadDeleted.pipe(debounceTime(300)).subscribe((_) => this.reload()),

      this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
        this.isSmallScreen = result.matches;
      })
    ]);
    const statusColumn = {
      id: 'app.favorites.status',
      key: 'properties.epmCore:status',
      title: 'APP.DOCUMENT_LIST.COLUMNS.EPM_STATUS',
      type: 'text',
      class: 'adf-ellipsis-cell narrow-cell statusColumn',
      sortable: true,
      desktopOnly: false,
      order: 80,
      sortingKey: 'properties.epmCore:status'
    } as DocumentListPresetRef;

    const revisionColumn = {
      id: 'app.favorites.revision',
      key: 'properties.epmCore:revision',
      title: 'APP.DOCUMENT_LIST.COLUMNS.EPM_REVISION_SHORT',
      type: 'text',
      class: 'adf-ellipsis-cell narrow-cell',
      sortable: true,
      desktopOnly: false,
      order: 70,
      sortingKey: 'properties.pmCore:revision'
    } as DocumentListPresetRef;

    const versionColumn = {
      id: 'app.favorites.version',
      key: 'properties.cm:versionLabel',
      title: 'APP.DOCUMENT_LIST.COLUMNS.VERSIONSHORT',
      type: 'text',
      class: 'adf-ellipsis-cell narrow-cell',
      sortable: true,
      desktopOnly: true,
      order: 60,
      sortingKey: 'properties.cm:versionLabel'
    } as DocumentListPresetRef;

    this.columns = this.extensions.documentListPresets.favorites;
    this.columns = this.columns.map((column) => {
      if (column.id !== 'app.favorites.name') {
        column.class = 'adf-ellipsis-cell';
      }

      if (column.id === 'app.favorites.modifiedOn') {
        column.class = 'column-modifiedOn';
      }
      if (column.id === 'app.favorites.modifiedBy') {
        column.class = 'column-modifiedOn';
      }
      return column;
    });
    this.columns.push(statusColumn, revisionColumn, versionColumn);
    this.columns = this.columns.filter((column) => {
      if (column.id === 'app.favorites.size') {
        return false;
      }
      if (column.id === 'app.favorites.location') {
        return false;
      }
      return true;
    });
    this.columns = this.columns.sort(sortByOrder);
  }

  navigate(favorite: MinimalNodeEntryEntity) {
    const { isFolder, id } = favorite;

    // TODO: rework as it will fail on non-English setups
    const isSitePath = (path: PathInfo): boolean => path && path.elements && path.elements.some(({ name }: PathElementEntity) => name === 'Sites');

    if (isFolder) {
      this.contentApi
        .getNode(id)
        .pipe(map((node) => node.entry))
        .subscribe(({ path }: MinimalNodeEntryEntity) => {
          const routeUrl = isSitePath(path) ? '/libraries' : '/personal-files';
          this.router.navigate([routeUrl, id]);
        });
    }
  }

  onNodeDoubleClick(node: MinimalNodeEntity) {
    if (node && node.entry) {
      if (node.entry.isFolder) {
        this.navigate(node.entry);
      }

      if (node.entry.isFile) {
        this.showPreview(node, { location: this.router.url });
      }
    }
  }

  handleNodeClick(event: Event) {
    this.onNodeDoubleClick((event as CustomEvent).detail?.node);
  }
}
