/**
 * Based on the https://github.com/pointhi/leaflet-color-markers
 */
import * as Leaflet from 'leaflet';

const blueIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-blue.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const goldIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-gold.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const redIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-red.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const greenIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-green.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const orangeIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-orange.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const yellowIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-yellow.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const violetIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-violet.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const greyIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-grey.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const blackIcon = new Leaflet.Icon({
  iconUrl: 'assets/epm-workflow-extension/images/markers/marker-icon-2x-black.png',
  shadowUrl: 'assets/epm-workflow-extension/images/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const LeafletIcons: { name: string; icon: L.Icon; color: string }[] = [
  { name: 'blue', icon: blueIcon, color: '#2A81CB' },
  { name: 'gold', icon: goldIcon, color: '#FFD326' },
  { name: 'red', icon: redIcon, color: '#CB2B3E' },
  { name: 'green', icon: greenIcon, color: '#2AAD27' },
  { name: 'orange', icon: orangeIcon, color: '#CB8427' },
  { name: 'yellow', icon: yellowIcon, color: '#CAC428' },
  { name: 'violet', icon: violetIcon, color: '#9C2BCB' },
  { name: 'grey', icon: greyIcon, color: '#7B7B7B' },
  { name: 'black', icon: blackIcon, color: '#3D3D3D' }
];
