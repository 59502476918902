/**
 * REST API model
 * /slingshot/datalists/data/node/{store_type}/{store_id}/{id}
 */
export class DatalistsDataModel {
  items: DatalistsDataItemModel[];
  metadata: DataListMetadataModel;
  startIndex: number;
  totalRecords: number;
  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      if (input.items) {
        this.items = input.items.map((item: any) => {
          return new DatalistsDataItemModel(item);
        });
      }
      this.metadata = input.metadata ? new DataListMetadataModel(input.metadata) : undefined;
    }
  }
}

class DatalistsDataItemModel {
  actionLabels: object;
  actionSet: string;
  createdBy: DataValueModel;
  createdOn: Date;
  itemData: { [key: string]: DataValueModel };
  modifiedBy: DataValueModel;
  modifiedOn: Date;
  nodeRef: string;
  permissions: object;
  tags: any[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.createdOn = input.createdOn ? new Date(input.createdOn) : undefined;
      this.modifiedOn = input.modifiedOn ? new Date(input.modifiedOn) : undefined;
    }
  }
}

class DataListMetadataModel {
  parent: DataListMetadataParentModel;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.parent = input.parent ? new DataListMetadataParentModel(input.parent) : undefined;
    }
  }
}

class DataListMetadataParentModel {
  nodeRef: string;
  permissions: { userAccess: object };

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}

type DataValueModel = {
  value: string;
  displayValue: string;
};
