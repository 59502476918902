import { RestVariable } from '../rest/rest-variable';

/**
 * REST API model
 */
export class ProcessInstance {
  id: string;
  processDefinitionId: string;
  startUserId: string;
  startActivityId: string;
  startedAt: Date;
  endedAt: Date;
  completed: boolean;
  processVariables: RestVariable[];

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
