import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProcessService } from '../../../services/process.service';
import { FormModel } from '@alfresco/adf-core';
import { ProcessDynamicFormService } from '../../../services/process-dynamic-form.service';
import { WorkflowDefinitionsModel } from '../../../api/models/legacy/workflow-definitions.model';
import { WorkflowDefinitionModel } from '../../../api/models/legacy/workflow-definition.model';
import { ProcessDynamicFormData } from '../../../services/process-dynamic-form.data';
import { ActivatedRoute } from '@angular/router';
import { SitesService } from '@alfresco/adf-content-services';
import { Site, SitePaging } from '@alfresco/js-api';
import { Store } from '@ngrx/store';
import { getUserProfile } from '../../../../../../aca-shared/store/src/public-api';

@Component({
  selector: 'epm-process-new',
  templateUrl: './process-new.component.html',
  styleUrls: ['./process-new.component.scss']
})
export class ProcessNewComponent implements OnInit, OnDestroy {
  /** Process form containing BPMN and TYPE */
  createProcessForm: FormGroup = new FormGroup({
    site: new FormControl('', Validators.required),
    bpmn: new FormControl('', Validators.required)
  });
  get processBpmnControl(): AbstractControl {
    return this.createProcessForm.get('bpmn');
  }

  get processSiteControl(): AbstractControl {
    return this.createProcessForm.get('site');
  }

  /** Fetched process definitions available to select */
  processDefs: WorkflowDefinitionModel[];

  /** selected process definition */
  selectedProcessDef: WorkflowDefinitionModel;

  /** Underlying form model instance containing all form controls */
  formModel: FormModel = new FormModel();

  /** Unsubscribe all subscriptions on ngOnDestroy */
  private readonly onDestroy$ = new Subject<boolean>();

  showForm = false;

  userSites: Site[];

  isProcessBpmnsLoading: boolean = false;

  isUserSitesLoading: boolean = false;
  isLoading = false;

  constructor(
    private processService: ProcessService,
    private epmDynamicFormService: ProcessDynamicFormService,
    public processDynamicFormData: ProcessDynamicFormData,
    private route: ActivatedRoute,
    private sitesService: SitesService,
    private store: Store
  ) {}

  ngOnInit() {
    this.loadUserSites();
    // this.loadProcessBpmns();
    this.store.select(getUserProfile).subscribe((user) => {
      const searchedGroup = user.groups?.find((g) => g.id === 'GROUP_OBIEGI_WNIOSKODAWCA');
      if (searchedGroup || user.id === 'admin') {
        this.loadProcessBpmns();
      }
    });

    // get attachment nodes from URL
    this.route.queryParams.subscribe((params) => {
      if (params['nodes']) {
        const nodes = Array.isArray(params['nodes']) ? params['nodes'] : [params['nodes']];
        this.processDynamicFormData.attachmentsFromUrl.next(nodes);
      }
    });

    this.processDynamicFormData.formModel
      .pipe(
        takeUntil(this.onDestroy$),
        map((formModel) => {
          if (this._isIssueWorkflow()) {
            formModel.changeFieldVisibility('assoc_packageItems_added', false);
          }
          return formModel;
        })
      )
      .subscribe((formModel) => (this.formModel = formModel));
  }

  onBpmnChange(change: MatSelectChange) {
    this.showForm = false;

    // retrieve selected process definition object
    for (let def of this.processDefs) {
      if (def.name === change.value) {
        this.selectedProcessDef = def;
        break;
      }
    }
    this.epmDynamicFormService.createWorkflowStartFormModel(this.selectedProcessDef);
  }

  cancelStartProcess() {
    this._resetForm();
  }

  onSiteChange(change: MatSelectChange) {
    this.processDynamicFormData.choosenSiteId.next(change.value);
  }

  loadUserSites(): void {
    this.isUserSitesLoading = true;
    this._updateIsLoading();
    this.sitesService
      .getSites()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((sitePaging: SitePaging) => {
        const fetchedSites: Site[] = sitePaging?.list?.entries?.map((entry) => entry.entry) || [];
        this.userSites = fetchedSites;
        this.isUserSitesLoading = false;
        this._updateIsLoading();
      });
  }

  loadProcessBpmns(): void {
    this.isProcessBpmnsLoading = true;
    this._updateIsLoading();
    this.processService
      .getLegacyProcessDefinitions()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (processDefs: WorkflowDefinitionsModel) => {
          this.processDefs = processDefs.data.filter((processDef) => {
            // FOR NOW ONLY 3 PROCESSES ARE AVAILABLE
            return (
              processDef.name === 'activiti$stdWorkflow' ||
              processDef.name === 'activiti$stdWorkflow2' ||
              processDef.name === 'activiti$issueWorkflow'
            );
          });
          this.isProcessBpmnsLoading = false;
          this._updateIsLoading();
        },
        (error) => {
          this.isProcessBpmnsLoading = false;
          this._updateIsLoading();
          console.log(error);
        }
      );
  }

  onFormSubmit() {
    this.isLoading = true;
    const formValues = Object.assign({}, this.formModel.values); // shallow copy
    // TODO replace select boxes values from {} to value

    // parse attachments
    const attachmentList: string[] = [];
    if (formValues['assoc_packageItems_added']) {
      for (let nodeAssoc of formValues['assoc_packageItems_added']) {
        attachmentList.push('workspace://SpacesStore/' + nodeAssoc.id);
      }
      delete formValues['assoc_packageItems_added'];
    }

    // TODO replace packageItems [NodeChildAssociation] to something different
    this.processService
      .createProcess(this.selectedProcessDef.name, formValues, attachmentList)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (_) => {
          this.isLoading = false;
          if (this._isIssueWorkflow()) {
            this._resetIssueWorkflowForm();
          } else {
            this._resetForm();
          }
          this.processDynamicFormData.newProcessCreated.next(true);
        },
        (_) => {
          this.isLoading = false;
        }
      );
  }

  private _isIssueWorkflow(): boolean {
    return this.processBpmnControl.value === 'activiti$issueWorkflow';
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this._resetForm();
  }

  private _resetIssueWorkflowForm() {
    this.formModel.changeFieldValue('bpm_workflowDescription', '');
    this.formModel.changeFieldValue('epmWf_dataListWfDesc', '');
    this.formModel.changeFieldValue('bpm_comment', '');
    this.formModel.changeFieldValue('assoc_packageItems_added', null);
    this.formModel.changeFieldValue('epmIssueWf_planLocationLat', null);
    this.formModel.changeFieldValue('epmIssueWf_planLocationLng', null);
    this.formModel.changeFieldValue('epmIssueWf_photoRefs', []);
  }

  private _resetForm() {
    this.processDynamicFormData.resetFormModel();
    this.processDynamicFormData.attachmentsFromUrl.next([]);
    this.processDynamicFormData.choosenSiteId.next('');
    this.selectedProcessDef = null;
    this.isProcessBpmnsLoading = false;
    this.isUserSitesLoading = false;
    this.isLoading = false;
    this.showForm = false;
    this.createProcessForm.reset();
  }

  private _updateIsLoading() {
    this.isLoading = this.isUserSitesLoading || this.isProcessBpmnsLoading;
  }

  isSubmitDisabled(): boolean {
    return (
      !this.formModel.isValid ||
      !this.processBpmnControl.value ||
      !this.processSiteControl.value ||
      this.formModel.fields.length < 2 ||
      this.isLoading ||
      (this.formModel.values.epmWf_dataListWfType && this.formModel.values.epmWf_dataListWfType.length === 0)
    );
  }
}
