import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Node } from '@alfresco/js-api';
import { ContentLinkModel, LocalizedDatePipe, ThumbnailService } from '@alfresco/adf-core';

const RETRIEVE_METADATA_OPTION = 'retrieveMetadata';

@Component({
  selector: 'epm-selected-file-view',
  templateUrl: './selected-file-view.component.html',
  styleUrls: ['./selected-file-view.component.scss']
})
export class SelectedFileViewComponent implements OnInit {
  displayedColumns = ['icon', 'fileName', 'action'];

  @Input()
  selectedFiles;

  @Input()
  hasFile: boolean;

  @Input()
  selectedNode: Node;

  @Input()
  field;

  @Output()
  downloadFile: EventEmitter<Node> = new EventEmitter<Node>();

  @Output()
  removeAttachFile: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  fileClicked: EventEmitter<ContentLinkModel> = new EventEmitter<ContentLinkModel>();

  constructor(private localizedDatePipe: LocalizedDatePipe, private thumbnailService: ThumbnailService) {}

  ngOnInit(): void {}

  onRowClicked(file?: Node) {
    console.log(file);
  }

  onRemoveAttachFile(file: any) {
    this.removeAttachFile.emit(file);
  }

  onAttachFileClicked(nodeSelector: any) {
    this.fileClicked.emit(new ContentLinkModel(nodeSelector));
  }

  downloadContent(file: Node) {
    this.downloadFile.emit(file);
  }

  getIcon(mimeType: string): string {
    return this.thumbnailService.getMimeTypeIcon(mimeType);
  }

  getColumnValue(file, displayableCMProperty): string {
    if (!file.properties[displayableCMProperty.prefixedName]) {
      const fieldProperty = this.field.params.displayableCMProperties?.find((property) => property.name === displayableCMProperty.name);
      return fieldProperty.defaultValue ? this.checkDateTypeAndTransform(displayableCMProperty.dataType, fieldProperty.defaultValue) : '--';
    }
    return file.properties[displayableCMProperty.prefixedName]
      ? this.checkDateTypeAndTransform(displayableCMProperty.dataType, file.properties[displayableCMProperty.prefixedName])
      : '--';
  }

  checkDateTypeAndTransform(dataType, value): string {
    if (dataType === 'd:date') {
      return this.localizedDatePipe.transform(value);
    } else if (dataType === 'd:datetime') {
      return this.localizedDatePipe.transform(value, 'medium');
    }
    return value;
  }

  displayMenuOption(option: string): boolean {
    return this.field?.params?.menuOptions ? this.field.params.menuOptions[option] : option !== RETRIEVE_METADATA_OPTION;
  }
}
