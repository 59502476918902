<adf-datatable
  [rows]="rows"
  [columns]="columns"
  [sorting]="sorting"
  [loading]="isLoading"
  [stickyHeader]="stickyHeader"
  [selectionMode]="selectionMode"
  [multiselect]="multiselect"
  [resolverFn]="resolverFn"
  [contextMenu]="showContextMenu"
  (rowClick)="onRowClick($event)"
  (row-keyup)="onRowKeyUp($any($event))"
  (sorting-changed)="onSortingChanged($event)"
  [rowStyle]="{'minHeight': '30px'}">
  <!-- Add custom, fancy loading template" -->
  <!-- <adf-loading-content-template>
    <ng-template>
      <mat-progress-spinner
        class="adf-process-list-loading-margin"
        color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
      <ng-content select="adf-custom-loading-content-template"></ng-content>
    </ng-template>
  </adf-loading-content-template> -->

  <!-- Add custom "No content" template-->
  <adf-no-content-template>
    <ng-template>
      <adf-empty-content
                         icon="assessment"
                         [title]="(processInstanceQuery ? 'EPM.PROCESS_LIST.EMPTY.TITLE' : 'EPM.PROCESS_LIST.NO_QUERY.TITLE') | translate"
                         [subtitle]="(processInstanceQuery ? 'EPM.PROCESS_LIST.NO_QUERY.TITLE' : 'EPM.PROCESS_LIST.NO_QUERY.SUBTITLE')| translate">
      </adf-empty-content>
      <ng-content select="adf-custom-empty-content-template"></ng-content>
    </ng-template>
  </adf-no-content-template>
</adf-datatable>
