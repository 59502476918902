/**
 * Body for the generate issue report request
 */
export class IssueRequestFilterModel {
  siteId?: string;
  planRef?: string;
  priority?: string;
  category?: string;
  departments?: string[];
  startedBefore?: Date;
  startedAfter?: Date;
  startedBy?: string;
  currAssignedTo?: string;
  completed?: boolean;
  markerColorCategory?: string;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
