<div [ngClass]="field.className"
     [class.adf-invalid]="!field.isValid && isTouched()"
     *ngIf="hasOptions()">
  <label class="adf-label" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
  <input type="hidden"
         [value]="field.value"
         [(ngModel)]="field.value"
         (ngModelChange)="onFieldChanged(field)"
         [disabled]="field.readOnly || readOnly">
  <div *ngFor="let opt of field.options">
    <mat-checkbox
      [id]="field.id + '-' + opt.id"
      color="primary"
      [value]="opt.id"
      [required]="isRequired()"
      [disabled]="field.readOnly || readOnly"
      [checked]="isChecked(opt)"
      [matTooltip]="field.tooltip"
      (change)="onCheckboxChange($event)"
      matTooltipPosition="right"
      matTooltipShowDelay="1000">
      {{'EPM.FORM.VAR.' + field.id.toUpperCase().replace('_', '.') + "_OPTIONS." + opt.name.toUpperCase() | translate }}
      <span class="adf-asterisk" *ngIf="isRequired()" >*</span>
    </mat-checkbox>
  </div>
  <error-widget [error]="field.validationSummary"></error-widget>
  <error-widget *ngIf="isInvalidFieldRequired() && isTouched()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
</div>
