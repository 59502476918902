export class Item {
  createdAt: string;
  size: number;
  createdBy: string;
  modifiedAt: Date;
  name: string;
  modifiedBy: string;
  id: string;
  mimeType: string;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
