import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PlanFilerModel } from '../../plan/plan-filter/plan-filer.model';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  selectedPlan$: BehaviorSubject<PlanFilerModel | false> = new BehaviorSubject(false);

  setSelectedPlan(plan: PlanFilerModel | false) {
    this.selectedPlan$.next(plan);
  }
}
