import { Injectable } from '@angular/core';
import { FormModel, FormRenderingService, NumberWidgetComponent, TextWidgetComponent } from '@alfresco/adf-core';
import { AttachFileWidgetComponentComponent } from '../components/form/widgets/attach-file/attach-file-widget-component.component';
import { removeWfDefKeyPrefix } from '../api/utils/workflow';
import { DropdownWidgetComponent } from '../components/form/widgets/dropdown/dropdown-widget.component';
import { MultiCheckboxComponent } from '../components/form/widgets/multi-checkbox/multi-checkbox.component';
import { EpmwfCommentsComponent } from '../components/form/widgets/epmwf-comments/epmwf-comments.component';
import { StandardWorkflowFormRenderer } from '../components/form/workflow/data-list/standard-workflow-form-renderer';
import { DefaultWorkflowFormRenderer } from '../components/form/workflow/default-workflow-form-renderer';
import { WorkflowFormRenderer } from '../components/form/workflow/workflow-form-renderer';
import { ProcessService } from './process.service';
import { take, switchMap } from 'rxjs/operators';
import { DlTypeComponent } from '../components/form/widgets/dl-type/dl-type.component';
import { ProcessDynamicFormData } from './process-dynamic-form.data';
import { WorkflowDefinitionModel } from '../api/models/legacy/workflow-definition.model';
import { RestFormModel } from '../api/models/rest-form-model/rest-form-model';
import { TaskInstance } from '../api/models/task/task-instance';
import { Item } from '../api/models/item/item';
import { HiddenInputComponent } from '../components/form/widgets/hidden-input/hidden-input.component';
import { OutcomesComponent } from '../components/form/widgets/outcomes/outcomes.component';
import { StandardWorkflow2FormRenderer } from '../components/form/workflow/data-list/standard-workflow-2-form-renderer';
import { StdWf2CompliantMaterialComponent } from '../components/form/widgets/stdwf2-compliant-material/std-wf2-compliant-material.component';
import { EpmPeopleWidgetComponent } from '../components/form/widgets/epm-people/epm-people-widget.component';
import { Stdwf2AdditionalSpecialistWidgetComponent } from '../components/form/widgets/stdwf2-additional-specialist/stdwf2-additional-specialist-widget.component';
import { IssueWorkflowFormRenderer } from '../components/form/workflow/data-list/issue-workflow-form-renderer';
import { PlanWidgetComponent } from '../components/form/widgets/plan-widget/plan-widget.component';
import { PhotoUploadWidgetComponent } from '../components/form/widgets/photo-upload/photo-upload-widget.component';

/**
 * Services handling workflow form generation
 */
@Injectable({
  providedIn: 'root'
})
export class ProcessDynamicFormService {
  constructor(
    private formRenderingService: FormRenderingService,
    private processService: ProcessService,
    private stdWorkflowRenderer: StandardWorkflowFormRenderer,
    private stdWorkflow2Renderer: StandardWorkflow2FormRenderer,
    private processDynamicFormData: ProcessDynamicFormData,
    private issueWorkflowFormRenderer: IssueWorkflowFormRenderer,
    private defaultWorkflowRenderer: DefaultWorkflowFormRenderer
  ) {
    // INFO: it has special behaviour - value array is converted to the string separated by ','
    this.formRenderingService.setComponentTypeResolver('epm-upload', () => AttachFileWidgetComponentComponent, true);

    // INFO epm-dropdown, because type "dropdown" has a special behaviour - it reads values as string but saves as {id:string, name: string} - more info in updateForm() function
    // TODO rename all widget component to have Widget in name
    this.formRenderingService.setComponentTypeResolver('epm-dropdown', () => DropdownWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('multi-checkbox', () => MultiCheckboxComponent, true);
    this.formRenderingService.setComponentTypeResolver('epmwf-comments', () => EpmwfCommentsComponent, true);
    this.formRenderingService.setComponentTypeResolver('epmwf-dltype', () => DlTypeComponent, true);
    this.formRenderingService.setComponentTypeResolver('hidden-input', () => HiddenInputComponent, true);
    this.formRenderingService.setComponentTypeResolver('outcome-buttons', () => OutcomesComponent, true);
    this.formRenderingService.setComponentTypeResolver('stdWf2-compliantMaterial', () => StdWf2CompliantMaterialComponent, true);
    this.formRenderingService.setComponentTypeResolver('epm-people', () => EpmPeopleWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('stdWf2-additionalSpecialist', () => Stdwf2AdditionalSpecialistWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('plan', () => PlanWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('epm-photo-upload', () => PhotoUploadWidgetComponent, true);

    // textbox for now
    this.formRenderingService.setComponentTypeResolver('int', () => NumberWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('long', () => NumberWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('noderef', () => TextWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('qname', () => TextWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('person', () => TextWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('workflowPackage', () => TextWidgetComponent, true);
    this.formRenderingService.setComponentTypeResolver('base', () => TextWidgetComponent, true);
  }

  /**
   * Create workflow start form model.
   * @param wfDefModel
   */
  createWorkflowStartFormModel(wfDefModel: WorkflowDefinitionModel): void {
    this.processDynamicFormData.wfDefModel = wfDefModel;
    this.processDynamicFormData.updateFormModel(new FormModel()); // reset state
    const wfDefKeyClear = removeWfDefKeyPrefix(wfDefModel.name);
    this.processDynamicFormData.renderer = this.getRenderer(wfDefKeyClear);

    this.processService
      .getProcessDefinitionStartModel(wfDefModel.id)
      .pipe(
        take(1),
        switchMap((restForm) => {
          this.processDynamicFormData.restFormModel = restForm;
          return this.processDynamicFormData.renderer.createStartForm(restForm, false);
        })
      )
      .subscribe((form: FormModel) => {
        return this.processDynamicFormData.updateFormModel(form);
      });
  }

  /**
   * Create workflow edit task form model.
   * @param taskInstance
   * @param taskVariables
   * @param taskRestFormModel
   * @param taskAttachments
   * @param readonly
   */
  createWorkflowEditTaskFormModel(
    taskInstance: TaskInstance,
    taskVariables: {},
    taskRestFormModel: RestFormModel[],
    taskAttachments: Item[],
    readonly = false
  ): void {
    this.processDynamicFormData.restFormModel = taskRestFormModel;
    this.processDynamicFormData.updateFormModel(new FormModel()); // reset state
    const procDefKey = removeWfDefKeyPrefix(taskInstance.processDefinitionId.replace(/:[0-9:]*/, ''));
    this.processDynamicFormData.renderer = this.getRenderer(procDefKey);
    this.processDynamicFormData.renderer
      .createTaskForm(taskInstance, taskVariables, taskRestFormModel, taskAttachments, readonly)
      .pipe(take(1))
      .subscribe((form: FormModel) => this.processDynamicFormData.updateFormModel(form));
  }

  private getRenderer(wfDefKey: string): WorkflowFormRenderer {
    let renderer: WorkflowFormRenderer;
    switch (wfDefKey) {
      case StandardWorkflowFormRenderer.key:
        renderer = this.stdWorkflowRenderer;
        break;
      case StandardWorkflow2FormRenderer.key:
        renderer = this.stdWorkflow2Renderer;
        break;
      case IssueWorkflowFormRenderer.key:
        renderer = this.issueWorkflowFormRenderer;
        break;
      default:
        renderer = this.defaultWorkflowRenderer;
    }
    return renderer;
  }
}
