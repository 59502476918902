import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { Node } from '@alfresco/js-api';
import { NotificationService } from '@alfresco/adf-core';
import { ContentNodeSelectorComponentData, NodeAction } from '@alfresco/adf-content-services';
import { EpmContentNodeSelectorComponent } from '../epm-content-node-selector/epm-content-node-selector.component';

@Injectable({
  providedIn: 'root'
})
export class EpmContentSelectorService {
  sourceNodeNotFound = false;

  constructor(private notificationService: NotificationService, private dialog: MatDialog) {}

  openFileSelectDialog(
    currentFolderId?: string,
    selectionMode?: string,
    isAllFileSources?: boolean,
    restrictRootToCurrentFolderId?: boolean,
    showDropdownSiteList?: boolean
  ): Observable<Node[]> {
    const select = new Subject<Node[]>();
    select.subscribe({ complete: this.close.bind(this) });
    const data = {
      title: 'Select a file',
      actionName: NodeAction.ATTACH,
      currentFolderId,
      restrictRootToCurrentFolderId,
      select,
      selectionMode,
      isSelectionValid: (entry: Node) => entry.isFile,
      showFilesInResult: true,
      showDropdownSiteList: showDropdownSiteList ? showDropdownSiteList : false,
      showLocalUploadButton: isAllFileSources
    } as ContentNodeSelectorComponentData;
    this.openContentNodeDialog(data, 'adf-content-node-selector-dialog', '66%');
    return select;
  }

  private openContentNodeDialog(data: ContentNodeSelectorComponentData, currentPanelClass: string, chosenWidth: string) {
    const contentNodeDialog = this.dialog.open(EpmContentNodeSelectorComponent, { data, panelClass: currentPanelClass, width: chosenWidth });
    contentNodeDialog.afterOpened().subscribe(() => {
      if (this.sourceNodeNotFound) {
        this.notificationService.showWarning('ADF_CLOUD_TASK_FORM.ERROR.DESTINATION_FOLDER_PATH_ERROR');
      }
    });
    contentNodeDialog.afterClosed().subscribe(() => {
      this.sourceNodeNotFound = false;
    });
  }

  close() {
    this.dialog.closeAll();
  }
}
