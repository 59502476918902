<div class="outcomes-widget {{field.className}}">
  <label class="adf-label" [attr.for]="field.id">{{field.name | translate }}<span class="adf-asterisk" *ngIf="isRequired()">*</span></label>
  <br>
  <ng-container *ngFor="let outcome of field.options">
    <button [id]="'aca-form-outcome-' + outcome.name | formatSpace"
            mat-raised-button
            color="accent"
            class="outcome-button"
            [disabled]="isButtonDisabled(outcome.id)"
            (click)="onOutcomeClicked(outcome)">
      {{outcome.name | translate | uppercase}}
    </button>
  </ng-container>
</div>
