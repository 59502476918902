<div
  class="aca-custom-name-column"
  [ngClass]="{
    'aca-name-column-container': isFile && isFileWriteLocked
  }"
>
  <span
    role="link"
    [attr.aria-label]="
      (isFile ? 'CUSTOM_NAME_COLUMN.ACCESSIBILITY.FILE_LINK_ARIA_LABEL' : 'CUSTOM_NAME_COLUMN.ACCESSIBILITY.FOLDER_LINK_ARIA_LABEL')
        | translate: { name: displayText$ | async }
    "
    class="adf-datatable-cell-value"
    title="{{ node | adfNodeNameTooltip }}"
    (click)="onLinkClick($event)"
    (keyup.enter)="onLinkClick($event)"
  >
  {{ displayText$ | async }}
</span>

  <!-- <ng-container *ngIf="isFile && isFileWriteLocked">
    <aca-locked-by [node]="context.row.node"></aca-locked-by>
  </ng-container> -->
</div>
