import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AppConfigService, DataRowEvent, DataTableSchema, DEFAULT_PAGINATION, PaginationModel, TranslationService } from '@alfresco/adf-core';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ProcessService } from '../../../../services/process.service';
import { TASKS_ORDER_BY_DIRECTION, TASKS_ORDER_BY_PROP, TasksQueryRequestData } from '../../../../api/models/task/tasks-query.model';
import { TasksDataService } from '../tasks-data.service';
import { Router } from '@angular/router';
import { AppStore, SnackbarErrorAction } from '@alfresco/aca-shared/store';
import { Store } from '@ngrx/store';

const PRESET_KEY = 'epm-task-list.presets';
const TASK_LIST_COLUMNS_DEFAULT_MODEL = {
  default: [
    {
      key: 'name',
      type: 'text',
      title: 'ADF_TASK_LIST.PROPERTIES.NAME',
      sortable: true,
      desktopOnly: false
    },
    {
      key: 'created',
      type: 'text',
      title: 'ADF_TASK_LIST.PROPERTIES.CREATED',
      cssClass: 'hidden',
      sortable: true
    },
    {
      key: 'assignee',
      type: 'text',
      title: 'ADF_TASK_LIST.PROPERTIES.ASSIGNEE',
      cssClass: 'hidden',
      sortable: true
    }
  ]
};

@Component({
  selector: 'epm-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent extends DataTableSchema implements OnInit, OnDestroy {
  @Output() error = new EventEmitter<any>();

  rows: any[] = [];
  sorting: any[] = ['created', 'desc'];
  isLoading: boolean = true;

  selectedRow: any;
  size: number = DEFAULT_PAGINATION.maxItems;
  page: number = 0;
  pagination: BehaviorSubject<PaginationModel>;
  tasksQuery = new TasksQueryRequestData();
  private readonly onDestroy$ = new Subject<boolean>();

  constructor(
    appConfigService: AppConfigService,
    private processService: ProcessService,
    private translation: TranslationService,
    public tasksDataService: TasksDataService,
    private router: Router,
    private store: Store<AppStore>
  ) {
    super(appConfigService, PRESET_KEY, TASK_LIST_COLUMNS_DEFAULT_MODEL);
    this.size = 500; // TODO: create better API with all filters. Filtering shouldn't be in the browser

    this.pagination = new BehaviorSubject<PaginationModel>({
      maxItems: this.size,
      skipCount: 0,
      totalItems: 0
    });
  }

  ngOnInit(): void {
    // try to restore state
    this.restoreState();

    // subscribe to filters change
    this.tasksDataService.filters$.pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      if (data) {
        this.tasksQuery = data;
        this.load();
      }
    });
  }

  onRowSelect(event: CustomEvent) {
    this.selectedRow = event.detail.selection;
  }

  onRowUnselect(event: CustomEvent) {
    this.selectedRow = event.detail.selection;
  }

  onRowClicked(item: DataRowEvent) {
    this.router.navigate(['/epm/task', item.value.getValue('id'), 'view']);
  }

  onRowKeyUp(event: CustomEvent) {
    if (event.detail.keyboardEvent.key == 'Enter') {
      event.preventDefault();
      console.log(event.detail.row.getValue('id'));
    }
  }

  /**
   * For the PaginationComponent target property
   * @param params
   */
  updatePagination(params: PaginationModel) {
    const needsReload = params.maxItems || params.skipCount;
    this.size = params.maxItems;
    this.page = this.currentPage(params.skipCount, params.maxItems);
    if (needsReload) {
      this.load();
    }
  }

  formatProcessDefinitionId(processDefinitionId: string): string {
    const key = processDefinitionId?.replace(/:.*/, '').toUpperCase();
    return this.tryToTranslate('EPM.PROCESS.LABEL', key);
  }

  formatPriority(priority: number): string {
    return this.tryToTranslate('EPM.FORM.PRIORITY', priority?.toString());
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.saveState();
  }

  private currentPage(skipCount: number, maxItems: number): number {
    return skipCount && maxItems ? Math.floor(skipCount / maxItems) : 0;
  }

  private load() {
    this.isLoading = true;

    // set default order
    this.tasksQuery.orderByDirection = TASKS_ORDER_BY_DIRECTION.DESC;
    this.tasksQuery.orderProp = TASKS_ORDER_BY_PROP.STARTED_AT;
    this.tasksQuery.maxItems = this.size;
    this.tasksQuery.skipCount = this.page * this.size;

    this.processService
      .getTasks(this.tasksQuery)
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(
        (response) => {
          // update tasks table data
          const tasks = response.list?.entries?.map((e) => e.entry);
          this.rows = tasks ? tasks : [];

          // update pagination
          const paginationModel: PaginationModel = {
            count: response.list?.entries?.length,
            maxItems: this.size,
            skipCount: this.page * this.size,
            totalItems: response.list?.pagination?.totalItems
          };
          this.pagination.next(paginationModel);
        },
        (error) => {
          this.error.emit(error);
          this.store.dispatch(new SnackbarErrorAction(this.translation.instant('EPM.TASK_LIST.ERROR.FETCH')));
          this.clearList();
        }
      );
  }

  private clearList(): void {
    this.rows = [];
    this.pagination.next(new PaginationModel());
    this.saveState();
  }

  private saveState() {
    this.tasksDataService.listState = {
      rows: this.rows,
      pagination: this.pagination.value,
      tasksQuery: this.tasksQuery
    };
  }

  private restoreState(): void {
    // for now, do not restore state
    // if (this.tasksDataService.listState) {
    //   const prevState = this.tasksDataService.listState;
    //   if (prevState.rows) {
    //     this.rows = prevState.rows;
    //   }
    //   if (prevState.pagination) {
    //     this.pagination.next({
    //       count: prevState.pagination.count,
    //       maxItems: prevState.pagination.maxItems,
    //       skipCount: prevState.pagination.skipCount,
    //       totalItems: prevState.pagination.totalItems
    //     });
    //   }
    //   if (prevState.tasksQuery) {
    //     this.tasksQuery = prevState.tasksQuery;
    //   }
    //   this.isLoading = false;
    // } else {
    //   // do nothing if there is no previous state
    //   //this.load();
    // }
    //this.load();
  }

  private tryToTranslate(key: string, subkey: string): string {
    if (key && subkey) {
      const translation = this.translation.instant(`${key}.${subkey}`);
      if (translation == `${key}.${subkey}`) {
        return subkey;
      }
      return translation;
    }
    return '';
  }
}
