import { TaskInstance } from './task-instance';

/**
 * REST API model
 */
export class TaskInstanceEntry {
  entry: TaskInstance;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.entry = input.entry ? new TaskInstance(input.entry) : undefined;
    }
  }
}
