<div class="adf-people-search-field" *ngIf="showPeopleSearch">
<mat-form-field class="adf-search-text-container">
  <input
    matInput
    placeholder="{{ 'EPM.PEOPLE_SEARCH.PLACEHOLDER' | translate }}"
    type="text"
    id="userSearchText"
    [value]=""
    [formControl]="searchUserControl"
    data-automation-id="adf-people-search-input"
    [cdkTrapFocusAutoCapture]="showPeopleSearch" [cdkTrapFocus]="showPeopleSearch">
</mat-form-field>
<ng-container *ngIf="searchedUsers; let users">
  <div class="adf-search-list-container" id="search-people-list" *ngIf="users.length > 0">
    <adf-datatable
                   #dataTable
                   class="adf-task-people-list"
                   [rows]="users"
                   [actions]="false"
                   [showHeader]="showHeader"
                   (rowClick)="onSelectUser($event)"
                   (rowDblClick)="onSelectUser($event)">
      <data-columns>
        <data-column key="firstName">
          <ng-template let-entry="$implicit">
            <div class="adf-people-pic">
              {{getInitialUserName(entry.row.obj.firstName, entry.row.obj.lastName)}}
            </div>
          </ng-template>
        </data-column>
        <data-column key="fullName" class="adf-full-width">
          <ng-template let-entry="$implicit">
            <div [attr.data-automation-id]="'adf-people-full-name-'+ getDisplayUser(entry.row.obj.firstName, entry.row.obj.lastName, '-')"
                 class="adf-people-full-name">{{ getDisplayUser(entry.row.obj.firstName, entry.row.obj.lastName, ' ') }}
            </div>
          </ng-template>
        </data-column>
      </data-columns>
    </adf-datatable>
  </div>
</ng-container>
</div>
