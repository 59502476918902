import { IssueRequestFilterModel } from '../../../api/models/legacy/issue-request-filter.model';
import { PROCESS_STATUS } from '../../../model/process-list-filter';

/**
 * Data emitted from the plan-filter component
 */
export class PlanFilerModel {
  planFormGroup: {
    site: string;
    plan: string;
  };

  issuesFormGroup: {
    priority: string;
    category: string;
    departments: string[];
    startedBefore: Date;
    startedAfter: Date;
    startedBy: string;
    currAssignedTo: string;
    status: string;
    markerColorCategory: string;
  };

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }

  toIssueReportRequestModel() {
    const model = new IssueRequestFilterModel();
    if (this.planFormGroup) {
      if (this.planFormGroup.plan) {
        model.planRef = this.planFormGroup.plan;
      }
      if (this.planFormGroup.site) {
        model.siteId = this.planFormGroup.site;
      }
    }
    if (this.issuesFormGroup) {
      if (this.issuesFormGroup.priority) {
        model.priority = this.issuesFormGroup.priority;
      }
      if (this.issuesFormGroup.category) {
        model.category = this.issuesFormGroup.category;
      }
      if (this.issuesFormGroup.departments) {
        model.departments = this.issuesFormGroup.departments;
      }
      if (this.issuesFormGroup.startedBefore) {
        model.startedBefore = this.issuesFormGroup.startedBefore; //.format("yyyy-MM-DD");
      }
      if (this.issuesFormGroup.startedAfter) {
        model.startedAfter = this.issuesFormGroup.startedAfter; //.format("yyyy-MM-DD");
      }
      if (this.issuesFormGroup.startedBy) {
        model.startedBy = this.issuesFormGroup.startedBy;
      }
      if (this.issuesFormGroup.currAssignedTo) {
        model.currAssignedTo = this.issuesFormGroup.currAssignedTo;
      }

      if (this.issuesFormGroup.status) {
        if (this.issuesFormGroup.status === PROCESS_STATUS.ACTIVE) {
          model.completed = false;
        } else if (this.issuesFormGroup.status === PROCESS_STATUS.COMPLETED) {
          model.completed = true;
        }
      }
      if (this.issuesFormGroup.markerColorCategory) {
        model.markerColorCategory = this.issuesFormGroup.markerColorCategory;
      }
    }
    return model;
  }
}
