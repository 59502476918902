import {
  ContentNodeSelectorPanelComponent,
  ContentNodeSelectorPanelService,
  CustomResourcesService,
  SEARCH_QUERY_SERVICE_TOKEN,
  SearchQueryBuilderService,
  SitesService
} from '@alfresco/adf-content-services';
import { UserPreferencesService, NodesApiService, UploadService, AppConfigService } from '@alfresco/adf-core';
import { NodePaging, SiteEntry } from '@alfresco/js-api';
import { Component, Inject } from '@angular/core';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';

@Component({
  selector: 'epm-content-node-selector-panel',
  templateUrl: './epm-content-node-selector-panel.component.html',
  styleUrls: ['./epm-content-node-selector-panel.component.scss']
})
export class EpmContentNodeSelectorPanelComponent extends ContentNodeSelectorPanelComponent {
  constructor(
    customResourcesService: CustomResourcesService,
    @Inject(SEARCH_QUERY_SERVICE_TOKEN) public queryBuilderService: SearchQueryBuilderService,
    userPreferencesService: UserPreferencesService,
    nodesApiService: NodesApiService,
    uploadService: UploadService,
    sitesService: SitesService,
    appConfigService: AppConfigService,
    contentNodeSelectorPanelService: ContentNodeSelectorPanelService,
    public processDynamicFormData: ProcessDynamicFormData
  ) {
    super(
      customResourcesService,
      queryBuilderService,
      userPreferencesService,
      nodesApiService,
      uploadService,
      sitesService,
      appConfigService,
      contentNodeSelectorPanelService
    );
    this.folderIdToShow = processDynamicFormData.choosenSiteId.getValue();
  }

  onFolderLoaded(nodePaging: NodePaging): void {
    super.onFolderLoaded(nodePaging);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  siteChanged(chosenSite: SiteEntry): void {
    super.siteChanged(chosenSite);
  }
}
