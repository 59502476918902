import { RuleContext } from '@alfresco/adf-extensions';
import { hasLockedFiles, isAdmin, isNotLibraries, isNotTrashcan } from '@alfresco/aca-shared/rules';

/**
 * Checks if user can create a new workflow.
 * JSON ref: `app.selection.canRemoveFavorite`
 */
export const isStartWorkflowVisible = (context: RuleContext): boolean =>
  [onlyFilesSelected(context), isNotTrashcan(context), !hasLockedFiles(context), isNotLibraries(context)].every(Boolean);

/**
 * Only admin and GROUP_SITE_CREATORS can create a new site.
 * JSON ref: `epm.canCreateLibrary`
 */
export function canCreateLibrary(context: RuleContext): boolean {
  if (isAdmin(context)) {
    return true;
  }
  return !!context.profile.groups?.find((g) => g.id === 'GROUP_SITE_CREATORS');
}

function onlyFilesSelected(context: RuleContext): boolean {
  if (context.selection.isEmpty) {
    return false;
  }
  if (context.selection.folder) {
    return false;
  }
  if (context.selection.file) {
    return true;
  }
  for (let node of context.selection.nodes) {
    if (!node.entry.isFile) {
      return false;
    }
  }
  return true;
}
