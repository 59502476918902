<div class="epm-photo-upload-widget {{field.className}}" [class.adf-invalid]="!field.isValid"
     [class.adf-readonly]="field.readOnly">
  <label class="adf-label" [attr.for]="field.id">{{ field.name | translate }}<span class="adf-asterisk"
                                                                                   *ngIf="isRequired()">*</span></label>
  <div class="epm-photo-upload-widget-container">

    <div>
      <div *ngIf="hasFile" fxLayout="column" fxLayoutAlign="center" >
        <div *ngFor="let photoUrl of photoUrlsDynamic" class="epm-photo-item-wrapper">
          <img [src]="photoUrl" alt="Issue photo" width="100%">
          <button *ngIf="allowPreviousFileRemoval(photoUrl);" mat-icon-button [id]="'file-'+photoUrl+'-remove'" color="warn"
                  (click)="removeFile(photoUrl);" (keyup.enter)="removeFile(photoUrl);">
            <mat-icon class="mat-24">highlight_off</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="(!hasFile || multipleOption) && !field.readOnly">
      <button mat-raised-button color="primary" (click)="uploadFiles.click()" [matTooltip]="field.tooltip"
              matTooltipPosition="above" matTooltipShowDelay="1000">
        {{ 'FORM.FIELD.UPLOAD' | translate }}<mat-icon>file_upload</mat-icon>

        <input #uploadFiles type="file" accept="image/*" capture="camera"
               [multiple]="multipleOption" [id]="field.form.nodeId"
               (change)="onFileChanged($event)" style="display: none;">
      </button>
    </div>
    <div class="lds-ellipsis" *ngIf="isUploading"><div></div><div></div><div></div><div></div></div>

    <div *ngIf="!hasFile && field.readOnly">
      {{ 'FORM.FIELD.NO_FILE_ATTACHED' | translate }}
    </div>
  </div>

  <error-widget [error]="field.validationSummary"></error-widget>
  <error-widget *ngIf="isInvalidFieldRequired()" required="{{ 'FORM.FIELD.REQUIRED' | translate }}"></error-widget>
</div>
