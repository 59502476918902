/**
 * legacy REST API Model
 * for the /epmflow/issue/photos/site/administration
 */
export class EpmIssuePhotosResponseModel {
  container: string;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
