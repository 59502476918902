/**
 * REST API Model
 * from the POST /process
 */
export class ProcessStartResponse {
  entry: ProcessStartResponseEntry;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.entry = input.entry ? new ProcessStartResponseEntry(input.entry) : undefined;
    }
  }
}

export class ProcessStartResponseEntry {
  id: string;
  processDefinitionId: string;
  businessKey: string;
  startedAt: Date;
  endedAt: Date;
  durationInMs: number;
  startActivityDefinitionId: string;
  endActivityDefinitionId: string;
  startUserId: string;
  deleteReason: string;

  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
    }
  }
}
