import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldModel, FormService, TranslationService, WidgetComponent } from '@alfresco/adf-core';
import { createFormFieldContainerJson } from '../../utils/form';
import { ProcessDynamicFormData } from '../../../../services/process-dynamic-form.data';

@Component({
  selector: 'aca-stdwf2-compliant-material',
  templateUrl: './std-wf2-compliant-material.component.html',
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class StdWf2CompliantMaterialComponent extends WidgetComponent implements OnInit {
  constructor(public formService: FormService, private processDynamicFormData: ProcessDynamicFormData, private translation: TranslationService) {
    super(formService);
  }

  ngOnInit(): void {
    this.handleCompliantMaterialDiff();
  }

  onFieldChanged(field: FormFieldModel) {
    super.onFieldChanged(field);
    this.handleCompliantMaterialDiff();
  }

  handleCompliantMaterialDiff() {
    if (this.field.value == true) {
      this.addCompliantMaterialDiff();
    } else {
      this.removeCompliantMaterialDiff();
    }
  }

  addCompliantMaterialDiff() {
    const compliantMaterialDiffContainerIdx = this.getCompliantMaterialDiffIndex();
    if (compliantMaterialDiffContainerIdx === -1) {
      const diffField = createFormFieldContainerJson(
        'stdWf2_compliantMaterialDiff',
        this.translation.instant('EPM.FORM.VAR.STDWF2.COMPLIANTMATERIALDIFF'),
        'text',
        null,
        true
      );
      const newFormFieldsJson = this.field.form.json.fields;
      const compliantMaterialIndex = this.getCompliantMaterialIndex();
      newFormFieldsJson.splice(compliantMaterialIndex + 1, 0, diffField);

      this.processDynamicFormData.updateFormFieldsModel(newFormFieldsJson, this.field.form.values);
    }
  }

  removeCompliantMaterialDiff() {
    const compliantMaterialDiffContainerIdx = this.getCompliantMaterialDiffIndex();
    if (compliantMaterialDiffContainerIdx !== -1) {
      this.field.form.json.fields.splice(compliantMaterialDiffContainerIdx, 1);
      this.processDynamicFormData.updateFormFieldsModel(this.field.form.json.fields, this.field.form.values);
    }
  }

  private getCompliantMaterialIndex() {
    return this.field.form.json.fields.findIndex((container) => container.name.includes('stdWf2_compliantMaterial'));
  }

  private getCompliantMaterialDiffIndex() {
    return this.field.form.json.fields.findIndex((container) => container.name.includes('stdWf2_compliantMaterialDiff'));
  }
}
