/**
 * REST API model from /epmflow/stdWf/retrieveUXData
 */
export class RetrieveUxDataModel {
  error: string;
  taskDepartments: string[];
  visibleMetadata: string[];
  enableAdditionalSpecialist: boolean;
  wfNameRegex: string;
  wfNameMetadata: string;

  constructor(input?: any) {
    if (input) {
      if (input.error) {
        this.error = input.error;
      } else {
        this.taskDepartments = input.taskDepartments;
        this.visibleMetadata = input.visibleMetadata;
        this.enableAdditionalSpecialist = input.enableAdditionalSpecialist === 'true' || input.enableAdditionalSpecialist === true;
        this.wfNameRegex = input.wfNameRegex;
        this.wfNameMetadata = input.wfNameMetadata;
      }
    }
  }
}
