import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormFieldOption, FormService, WidgetComponent } from '@alfresco/adf-core';
import { MatCheckboxChange } from '@angular/material/checkbox';

/**
 * Widget generating checkboxes.
 * For each field.option one checkbox is generated.
 *  Each option:
 *    id - value,
 *    name - visible name,
 * field.value contains the values
 *
 */
@Component({
  selector: 'epm-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss'],
  host: {
    '(click)': 'event($event)',
    '(blur)': 'event($event)',
    '(change)': 'event($event)',
    '(focus)': 'event($event)',
    '(focusin)': 'event($event)',
    '(focusout)': 'event($event)',
    '(input)': 'event($event)',
    '(invalid)': 'event($event)',
    '(select)': 'event($event)'
  },
  encapsulation: ViewEncapsulation.None
})
export class MultiCheckboxComponent extends WidgetComponent implements OnInit {
  constructor(public formService: FormService) {
    super(formService);
  }

  ngOnInit(): void {
    if (!this.field.value) {
      this.field.value = [];
    }
  }

  onCheckboxChange(change: MatCheckboxChange) {
    const isChecked = change.checked;
    const optValue = change.source.value;
    if (isChecked) {
      this.addToValues(optValue);
    } else {
      this.removeFromValues(optValue);
    }
    this.markAsTouched();
    this.onFieldChanged(this.field);
  }

  addToValues(optVal: string) {
    if (!this.field.value?.includes(optVal)) {
      this.field.value.push(optVal);
    }
  }

  removeFromValues(optVal: string) {
    if (this.field.value?.includes(optVal)) {
      this.field.value.splice(this.field.value.indexOf(optVal), 1);
    }
  }

  hasOptions(): boolean {
    return this.field.options && this.field.options.length !== 0;
  }

  isChecked(opt: FormFieldOption) {
    return this.field.value?.includes(opt.id);
  }
}
