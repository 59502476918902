import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { LegacyApiService } from '../../../../api/services/legacy-api.service';
import { DynamiclistsModel } from '../../../../api/models/epm/dynamiclists.model';
import { RetrieveUxDataModel } from '../../../../api/models/epm/retrieve-ux-data.model';
import { removeWfDefKeyPrefix } from '../../../../api/utils/workflow';
import { map } from 'rxjs/operators';

const DL_WORKFLOW_KEYS = ['stdWorkflow', 'stdWorkflow2'];

@Injectable({
  providedIn: 'root'
})
export class DlWorkflowService {
  constructor(private stdWorkflowApiService: LegacyApiService) {}

  fetchWfTypes(workflowDefKey: string): Observable<DynamiclistsModel> {
    const dlItemKey = mapWorkflowDefKeyToDataListItem(workflowDefKey);
    return from(this.stdWorkflowApiService.fetchWfTypes(dlItemKey));
  }

  retrieveUxData(dataListRef: string, dlTaskDefNodeRef?: string): Observable<RetrieveUxDataModel> {
    return from(this.stdWorkflowApiService.retrieveUxData(dataListRef, dlTaskDefNodeRef)).pipe(
      map((resp) => {
        if (resp.error) {
          throwError(() => new Error(resp.error));
        }
        return new RetrieveUxDataModel(resp);
      })
    );
  }
}

function mapWorkflowDefKeyToDataListItem(workflowDefKey: string) {
  workflowDefKey = removeWfDefKeyPrefix(workflowDefKey);
  switch (workflowDefKey) {
    case 'stdWorkflow':
      return 'stdWf:stdWorkflowDataListItem';
    case 'stdWorkflow2':
      return 'stdWf2:stdWorkflow2DataListItem';
    case 'issueWorkflow':
      return 'epmIssueWf:issueWorkflowDataListItem';
  }
  console.warn('Unknown data list item mapping: ' + workflowDefKey);
  return workflowDefKey;
}

export function isDlWorkflow(defKey: string) {
  defKey = removeWfDefKeyPrefix(defKey);
  return DL_WORKFLOW_KEYS.includes(defKey);
}

export function addDlWorkflowRequiredParams(formValues: object, dlWfNode: string, siteId: string, dlWfNodeName: string) {
  formValues['epmWf_dataListWfNode'] = dlWfNode;
  formValues['epmWf_dataListWfSiteid'] = siteId;
  formValues['epmWf_dataListWfType'] = dlWfNodeName;
}
