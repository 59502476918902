import { RestFormModelPagingList } from './rest-form-model-paging-list';

/**
 * REST API model
 */
export class RestFormModelList {
  list?: RestFormModelPagingList;
  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.list = input.list ? new RestFormModelPagingList(input.list) : undefined;
    }
  }
}
