import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[dlTypeValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DlTypeValidator,
      multi: true
    }
  ]
})
export class DlTypeValidator implements Validator {
  @Input('dlTypeValidator') processTypes;
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    const matchedType = this.processTypes.find((t) => t.name === value);
    if (matchedType) {
      return null;
    }
    return { validProcessType: true };
  }
}
