import { ProcessInstancePagingList } from './process-instance-paging-list';

/**
 * REST API model
 */
export class ProcessInstanceList {
  list?: ProcessInstancePagingList;
  constructor(input?: any) {
    if (input) {
      Object.assign(this, input);
      this.list = input.list ? new ProcessInstancePagingList(input.list) : undefined;
    }
  }
}
